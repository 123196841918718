import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { StepperService } from '../../services/stepper.service';
import { ThemeService } from '../../services/theme.service';
import { ApiService } from '../../services/api.service';
import { pulsatingButton, getCurrentState } from 'src/app/animations/animations';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Theme } from '../../models/Theme';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { GA4Events } from '../../models/constants/GA4Events';
import {LegalService} from '../../services/legal.service';

export interface DialogData {
  fixedServiceFee: string;
  variableServiceFee: string;
  variableServiceFeePercentage: string;
  currency: string;
  dispatcherName: string;
}
@Component({
  selector: 'app-booking-overview',
  templateUrl: './booking-overview.component.html',
  styleUrls: ['./booking-overview.component.scss'],
  animations: [pulsatingButton]
})
export class BookingOverviewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() next: () => void = () => {};
  @Input() screenHeight: number = 0;
  @Input() payEnabled: boolean = false;
  @ViewChild('pickup_container') private pickupContainer: ElementRef | undefined;

  public animationStateCounter: number = 0;
  public bookingDetails: any = {};
  private bookingDetailsSubscription!: Subscription;
  private configurationSubscription!: Subscription;
  public address: string = '';
  public venueName: string = '';
  public lineHeight: number = 0;
  public darkMode: boolean = true;
  public agreedToPPAndTC = false;
  private themeSubscription!: Subscription;
  private confirmPPAndTCSubscription!: Subscription;
  private dispatcherName: string = 'transportation provider';
  public complianceEnabled: boolean;

  constructor(private stepperService: StepperService,
              public themeService: ThemeService,
              private apiService: ApiService,
              private gaService: GoogleAnalyticsService,
              private legalService: LegalService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.setBookingDetailsSubscription();
    this.setConfigurationSubscription();
    this.setThemeSubscription();
    this.setConfirmPPAndTCSubscription();

    this.gaService.sendEvent(GA4Events.bookingOverview, 'true');
  }

  ngOnDestroy(): void {
    this.bookingDetailsSubscription.unsubscribe();
    this.configurationSubscription.unsubscribe();
    this.themeSubscription.unsubscribe();
    this.confirmPPAndTCSubscription.unsubscribe();
  }

  public confirmOverview(): void {
    this.next();
  }

  private setBookingDetailsSubscription(): void {
    this.bookingDetailsSubscription = this.stepperService.bookingDetails$.subscribe(bookingDetails => {
      this.bookingDetails = bookingDetails;
    });
  }

  private setConfirmPPAndTCSubscription(): void {
    this.confirmPPAndTCSubscription = this.legalService.isConfirmed$.subscribe(confirmed => this.agreedToPPAndTC = confirmed);
  }


  private setThemeSubscription(): void {
    this.themeSubscription = this.themeService.isDarkTheme$.subscribe(darkMode => {
      this.darkMode = darkMode;
    });
  }

  private setConfigurationSubscription(): void {
    this.configurationSubscription = this.apiService.butlerConfiguration$.subscribe(configuration => {
      if (configuration) {
        this.address = configuration.venueDetails.address;
        this.venueName = configuration.venueDetails.name;
        this.dispatcherName = configuration.companyDetails.displayName;
        this.complianceEnabled = configuration.complianceEnabled;
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.lineHeight = this.pickupContainer!.nativeElement.offsetHeight + 4;
    }, 10);
  }

  public nextState(): void {
    this.animationStateCounter += 1;
  }

  public getCurrentState(): string {
    if(this.hasAgreedToPPAndTC()) {
      return getCurrentState(this.animationStateCounter);
    }
    return 'void';
  }

  public hasAgreedToPPAndTC() {
    return this.agreedToPPAndTC || !this.complianceEnabled;
  }

  public hasServiceFee(): boolean {
    return this.bookingDetails?.carType?.fixedServiceFeeAmount > 0
      || this.bookingDetails?.carType?.variableServiceFeeAmount > 0;
  }

  public totalBaseFareAmount(): string {
    return this.bookingDetails.carType.currency + ' ' + this.bookingDetails.carType.basePrice.toFixed(2);
  }

  public totalServiceFeeAmount(): string {
    return this.bookingDetails.carType.currency + ' ' + (this.bookingDetails?.carType?.fixedServiceFeeAmount + this.bookingDetails?.carType?.variableServiceFeeAmount).toFixed(2);
  }

  public openBreakdown(): void {
    const panelClass = this.darkMode ? 'custom-dialog-container-dark' : 'custom-dialog-container-light';
    this.dialog.open(PriceBreakdownDialogComponent, {
      panelClass: panelClass,
      data: {
        fixedServiceFee: this.getFixedServiceFeeAmount(),
        variableServiceFee: this.getVariableServiceFeeAmount(),
        variableServiceFeePercentage: this.bookingDetails?.carType?.variableServiceFeePercentage,
        currency: this.bookingDetails.carType.currency,
        dispatcherName: this.dispatcherName
      },
    maxWidth: '90vw',
    width: '330px',
    minHeight: '330px',
    });
  }

  getFixedServiceFeeAmount() {
    return this.bookingDetails?.carType?.fixedServiceFeeAmount.toFixed(2);
  }

  getVariableServiceFeeAmount() {
    return this.bookingDetails?.carType?.variableServiceFeeAmount.toFixed(2);
  }

  public getDestinationAddress() {
    const fullDestination = this.bookingDetails.destination.value.split(',');
    const destinationCountry = fullDestination ? fullDestination.pop() : '';
    const destinationAddress = fullDestination ? fullDestination.pop() : '';
    return destinationAddress + ', ' + destinationCountry;

  }

}

@Component({
  selector: 'app-price-breakdown-dialog',
  templateUrl: 'price-breakdown-dialog.html',
  styleUrls: ['./booking-overview.component.scss'],
})
export class PriceBreakdownDialogComponent implements OnInit, OnDestroy {
  themeServiceSubscription: Subscription | undefined;
  public proColor: string = '';
  public darkMode: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PriceBreakdownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private themeService: ThemeService
  ) {}

  public closeDialog(): void {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
    this.setThemeSubscription();
  }

  private setThemeSubscription(): void {
    this.themeServiceSubscription = this.themeService.themeDetails$.subscribe(themeDetails => {
      this.proColor = themeDetails.primaryColor;
      this.darkMode = themeDetails.theme === Theme.DARK;
    });
  }

  ngOnDestroy(): void {
    if (this.themeServiceSubscription) {
      this.themeServiceSubscription.unsubscribe();
    }
  }

  protected readonly Number = Number;
}
