import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import Countries from '../../models/constants/Countries';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import {PhoneNumberValidator} from 'src/app/validators/PhoneNumberValidator';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
import {ErrorDialogComponent} from '../dialogs/error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {StepperService} from '../../services/stepper.service';
import {Subscription} from 'rxjs';
import {ThemeService} from '../../services/theme.service';
import {ApiService} from '../../services/api.service';
import {SelectCountryService} from '../../services/select-country.service';
import {pulsatingButton, getCurrentState} from 'src/app/animations/animations';
import {GoogleAnalyticsService} from '../../services/google-analytics.service';
import {GA4Events} from '../../models/constants/GA4Events';
import { ConfigurationStepType } from 'src/app/models/ConfigurationStep';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  animations: [pulsatingButton]
})
export class PersonalInformationComponent implements OnInit, OnDestroy {
  private bookingDetailsSubscription!: Subscription;
  private configurationSubscription!: Subscription;
  private selectedCountrySubscription!: Subscription;
  private themeSubscription!: Subscription;

  @Input() next: () => void = () => {};
  @Input() screenHeight: number = 0;
  @Input() payEnabled: boolean = false;

  public phoneUtil!: PhoneNumberUtil;
  public bookingDetails: any = {};
  public darkMode: boolean = true;
  public countries = Countries();
  public configurationStepType = ConfigurationStepType.NO_CONFIRMATION;
  selectedCountryCode = '';
  public confirmationDisabled: boolean = false;

  public countryForm = new FormControl(this.countries[0], [Validators.required]);
  public phoneForm = new FormControl('', [PhoneNumberValidator(this.countryForm.value)]);
  public firstNameForm = new FormControl('', [Validators.required, whitespaceValidator]);
  public lastNameForm = new FormControl('');
  public invoiceToggleForm = new FormControl(false);
  public invoiceMailForm = new FormControl('', [Validators.required,
    whitespaceValidator, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]);

  public formGroup = new FormGroup({
    countryCode: this.countryForm,
    phoneNumber: this.phoneForm,
    firstName: this.firstNameForm,
    lastName: this.lastNameForm,
    invoiceToggle: this.invoiceToggleForm,
    invoiceMail: this.invoiceMailForm
  });

  private phoneMargins = ['-2px', '6px'];
  @HostBinding('style.--phone-margin-top')
  public phoneMarginTop = this.phoneMargins[0];
  public animationStateCounter: number = 0;

  constructor(private stepperService: StepperService,
              private apiService: ApiService,
              private dialog: MatDialog,
              public themeService: ThemeService,
              private selectCountryService: SelectCountryService,
              private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.phoneUtil = PhoneNumberUtil.getInstance();
    this.themeSubscription = this.themeService.isDarkTheme$.subscribe(darkMode => this.darkMode = darkMode);
    this.setPhoneBrowserMargins();
    this.setCountryFormSubscription();
    this.setSelectedCountrySubscription();
    this.setConfigurationSubscription();
    this.setBookingDetailsSubscription();
    this.gaService.sendEvent(GA4Events.personalInformation, 'true');
  }

  ngOnDestroy(): void {
    this.bookingDetailsSubscription.unsubscribe();
    this.configurationSubscription.unsubscribe();
    this.selectedCountrySubscription.unsubscribe();
    this.themeSubscription.unsubscribe();
  }

  public nextState(): void {
    this.animationStateCounter += 1;
  }

  public getCurrentState(): string {
    if (this.isValid()) {
      return getCurrentState(this.animationStateCounter);
    }
    return 'void';
  }

  private setBookingDetailsSubscription(): void {
    this.bookingDetailsSubscription = this.stepperService.bookingDetails$.subscribe(bookingDetails => {
      this.bookingDetails = bookingDetails;
      if (bookingDetails.firstName) {
        let firstName: string = bookingDetails.firstName.value;
        this.firstNameForm.setValue(firstName);
      }

      if (bookingDetails.lastName) {
        let lastName: string = bookingDetails.lastName.value;
        this.lastNameForm.setValue(lastName);
      }


      if (bookingDetails.telephone) {
        let telephone: string = this.phoneUtil.parse(bookingDetails.telephone.value.toString()).getNationalNumber()!.toString();
        let countryCode: string = this.phoneUtil.getRegionCodeForNumber(this.phoneUtil.parse(bookingDetails.telephone.value))!.toLowerCase();
        this.phoneForm.setValidators(PhoneNumberValidator(this.countryForm.value));
        this.phoneForm.setValue(telephone);
        this.countryForm.setValue(this.countries.find(c => c.countryCode === countryCode));
        this.focusPhone(false);
      }

      if (bookingDetails.invoiceMail && bookingDetails.invoiceMail.value !== '') {
        this.invoiceToggleForm.setValue(true);
        this.invoiceMailForm.setValue(bookingDetails.invoiceMail.value);
      }
    });
  }

  private setConfigurationSubscription(): void {
    this.configurationSubscription = this.apiService.butlerConfiguration$.subscribe(configuration => {
      if (configuration) {
        this.configurationStepType = configuration.configurationSteps[configuration.configurationSteps.length - 1].type;
        const countryCode = configuration.countryISO2.toLowerCase();
        this.selectedCountryCode = countryCode;
        this.phoneForm.setValidators(PhoneNumberValidator(countryCode));
        const value = this.countries.find(value => value.countryCode === countryCode);
        if (value) {
          this.countryForm.setValue(value);
          this.selectCountryService.setSelectCountry(value);
        }
      }
    });
  }

  private setCountryFormSubscription(): void {
    this.countryForm.valueChanges.subscribe(country => {
      if (country) {
        const countryCode = country.countryCode;
        this.selectedCountryCode = countryCode;
        this.phoneForm.setValidators(PhoneNumberValidator(countryCode));
        this.phoneForm.updateValueAndValidity();
      }
    });
  }

  private setSelectedCountrySubscription(): void {
    this.selectedCountrySubscription = this.selectCountryService.selectedCountry$.subscribe(country => {
      this.countryForm.setValue(country);
    });
  }

  private setPhoneBrowserMargins(): void {
    // use different margins for Safari and Firefox
    const userAgent = navigator.userAgent.toLowerCase();
    if ((userAgent.indexOf('safari') != -1 || userAgent.indexOf('firefox') != -1) &&
      userAgent.indexOf('chrome') == -1 &&
      userAgent.indexOf('opera') == -1 &&
      userAgent.indexOf('opr') == -1
    ) {
      this.phoneMargins = ['-4px', '4px'];
      this.phoneMarginTop = this.phoneMargins[0];
    }
  }

  public confirmPersonalInformation(): void {
    if (!this.firstNameForm.valid) {
      return;
    }
    if (!this.phoneForm.valid) {
      this.dialog.open(ErrorDialogComponent, {data: {title: 'INVALID_PHONE_TITLE', message: 'INVALID_PHONE_MESSAGE'}});
    } else {
      try {
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(this.phoneForm.value!, this.countryForm.value!.countryCode);
        this.bookingDetails.firstName = {value: this.firstNameForm.value!.trim()};
        this.bookingDetails.lastName = {value: this.lastNameForm.value!.trim()};
        this.bookingDetails.telephone = {value: phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.E164)};
        this.bookingDetails.invoiceMail = {value: this.invoiceMailForm.value!.trim()};
        this.stepperService.updateBookingDetails(this.bookingDetails);
        this.next();
      } catch (e) {
        console.log(e);
      }
    }
  }

  public isValid(): boolean {
    return this.firstNameForm.valid && this.phoneForm.valid && this.isInvoiceEmailValid();
  }

  public isInvoiceEmailValid(): boolean {
    if (this.invoiceToggleForm.value) {
      return this.invoiceMailForm.valid;
    }
    return true;
  }

  public focusPhone(b: boolean): void {
    this.phoneMarginTop = this.phoneForm.value!.length > 0 || b ? this.phoneMargins[1] : this.phoneMargins[0];
  }

  public openSelectCountry(): void {
    this.selectCountryService.setSelectCountryOpen(true);
  }

  public isIOS(): boolean {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }
}

export function whitespaceValidator(form: FormControl): ValidationErrors | null {
  return form.value.startsWith(' ') ? {whitespace: true} : null;
}
