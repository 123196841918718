import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {PaymentService} from './payment.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuardService {

  constructor(private router: Router,
              private paymentService: PaymentService) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> | boolean {
    const params = route.queryParamMap;

    return new Promise<boolean>(resolve => {
      if (!params.get('t') || !window.location.pathname.includes('checkout') || !Number(params.get('i')) || !params.get('k')) {
        this.router.navigate(['404']).then();
        resolve(false);
      }
      this.paymentService.validatePaymentStatus(Number(params.get('t')));
      resolve(true);
    });

  }
}
