import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ThemeDetails } from '../models/ThemeDetails';
import { Theme } from '../models/Theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeDetailsSource: BehaviorSubject<ThemeDetails> = new BehaviorSubject<ThemeDetails>({
    theme: Theme.LIGHT,
    primaryColor: '#FFCC00',
    logoUrl: ''
  });
  public themeDetails$: Observable<ThemeDetails> = this.themeDetailsSource.asObservable();

  public isDark = false;

  private isDarkThemeSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isDark);
  public isDarkTheme$: Observable<boolean> = this.isDarkThemeSource.asObservable();

  constructor() {
  }

  public updateColorMode(darkMode: boolean): void {
    this.isDark = darkMode;
    this.isDarkThemeSource.next(this.isDark);
  }

  public updateTheme(themeDetails: ThemeDetails): void {
    this.themeDetailsSource.next(themeDetails);
  }

  public getVehicleIcon(icon: string): string {
    return `/assets/icons/vehicles/taxi-icon-${icon}.png`;
  }


}
