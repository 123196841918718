import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit {
  public partnerName: string = 'Partner';
  partnerUrl: string;
  public complianceEnabled: boolean;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.butlerConfiguration$.subscribe(conf => {
      if (conf) {
        this.partnerName = conf?.companyDetails.displayName!;
        this.partnerUrl = conf?.companyDetails.legalLink;
        this.complianceEnabled = conf.complianceEnabled;
      }
    });
  }
}
