<div class="toast toast-colour" [ngClass]="isSmallScreen ? 'toast-small-screen' : 'toast-fullscreen'">
  <div class="container">
    <div class="text">
      {{ 'COOKIE_TEXT' | translate}}
        <a class="click" [routerLink]="['cookies']" [queryParamsHandling]="'preserve'">
          {{ 'COOKIE_USE' | translate}}
        </a>
      <button mat-button (click)="acceptAllCookies()" [ngClass]="isDarkMode ? 'dark-accept' : 'light-accept'" class="cookie-button-accept cookie-button"> {{ 'ACCEPT_COOKIE' | translate }}</button>
      <button mat-button (click)="acceptFunctionalCookies()" [ngClass]="isDarkMode ? 'dark-reject' : 'light-reject'" class="cookie-button-reject cookie-button"> {{ 'REJECT_COOKIE' | translate }}</button>
    </div>
  </div>
</div>
