<div class="container">
  <div class="inner-container">
    <div class="head-title"> <h6> {{ 'SECURE_PAYMENT' | translate }}</h6> </div>
    <mat-divider class="divider"></mat-divider>
    <div *ngIf="showMsgError()" class="error">
      {{ msgError | translate }}
    </div>
    <div *ngIf="showSpinner()" class="center">
      <mat-spinner></mat-spinner>
    </div>
    <div class="payment-options">
      <div [hidden]="!showDropIn()" id="dropin-container"></div>
    </div>
  </div>
</div>
