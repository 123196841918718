<div class="pdf-container">
  <pdf-viewer [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              [autoresize]="true"
              [zoom-scale]="'page-fit'"
              class="pdf-viewer">
  </pdf-viewer>
  <div class="bottom-bar" [ngClass]="isDarkMode ? 'dark-bar' : 'light-bar'">
    <p>
      {{'SAVE_POLICY' | translate}}
    </p>
    <button mat-button
            [ngStyle]="{'background-color': this.proColor , 'text-transform': 'uppercase', 'color': isDarkMode ? 'black' : 'white'}"
            (click)="savePdf()">{{"SAVE" | translate}}</button>
  </div>
</div>