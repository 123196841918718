import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TripStates } from '../models/TripStates';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from './theme.service';
import { AdyenCheckoutConfiguration } from '../models/AdyenCheckoutConfiguration';
import { AdyenSessionResponse } from '../models/AdyenSessionResponse';
import { AdyenSessionErrorType } from '../models/AdyenSessionErrorType';

import AdyenCheckout from '@adyen/adyen-web';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import Core from '@adyen/adyen-web/dist/types/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private paymentStateSource: BehaviorSubject<TripStates> = new BehaviorSubject<TripStates>(TripStates.PENDING_PAYMENT);
  public paymentState$: Observable<TripStates> = this.paymentStateSource.asObservable();

  private mbtURLSource: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public mbtURL$: Observable<string | null> = this.mbtURLSource.asObservable();

  private reservationTimeSource: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public reservationTime$: Observable<string | null> = this.reservationTimeSource.asObservable();

  private tripId: number;
  private interval: any;
  private isDark: boolean = true;
  private dropIn: DropinElement;
  private checkout: Core;
  private checkoutConfiguration: AdyenCheckoutConfiguration;

  constructor(private httpClient: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private themeService: ThemeService) {
    themeService.isDarkTheme$.subscribe(isDark => this.isDark = isDark);
  }

  get qrId(): number { return +this.route.snapshot.queryParams['i'] || this.router.getCurrentNavigation()?.extractedUrl.queryParams['i']; }
  get hash(): string { return this.route.snapshot.queryParams['k'] || this.router.getCurrentNavigation()?.extractedUrl.queryParams['k']; }
  get checkoutUrl(): string { return `${window.location.origin}/checkout?i=${this.qrId}&k=${this.hash}&t=${this.tripId}&d=${this.isDark}`;}
  get paidTripUrl(): string { return `/butler/qr/1/${this.qrId}/paidTrip/${this.tripId}`; }

  public createDropIn(checkoutConfiguration: AdyenCheckoutConfiguration, sessionResponse: AdyenSessionResponse, tripId: number) {
    this.tripId = tripId;
    this.checkoutConfiguration = checkoutConfiguration;
    checkoutConfiguration.build(sessionResponse, this.checkoutUrl);

    this.mountDropin().then(() => checkoutConfiguration.dropinStateSource.next(AdyenSessionErrorType.DROP_IN_SUCCESS));

  }

  private async mountDropin() {
    this.checkout = await AdyenCheckout(this.checkoutConfiguration.checkoutConfigModel);
    this.dropIn = this.checkout.create('dropin').mount('#dropin-container');
  }

  public async remountDropin() {
    if (this.checkout && this.dropIn) {
      this.dropIn.remove();
      this.checkout.remove(this.dropIn);
      this.mountDropin().then();
    }
  }

  public updatePaymentStatus(): void {
    this.interval = setInterval(() => {
      this.validatePaymentStatus(this.tripId);
    }, 3000);
  }

  public validatePaymentStatus(tripId: number): void {
    this.tripId = tripId;
    const params = {k: this.hash};

    this.httpClient
      .post<any>(this.paidTripUrl, null, {params})
      .subscribe(response => {
        if (response && response.tripStatus !== TripStates.PENDING_PAYMENT) {
          clearInterval(this.interval);
          this.paymentStateSource.next(response.tripStatus);
          if (response.redirectionUrl) {
            this.mbtURLSource.next(response.redirectionUrl);
          }
          if(response.reservationTime) {
            this.reservationTimeSource.next(response.reservationTime);
          }
        }
      });
  }
}


