import { AdyenSessionResponse } from './AdyenSessionResponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { AdyenSessionErrorType } from './AdyenSessionErrorType';
import { PaymentService } from '../services/payment.service';

export class AdyenCheckoutConfiguration {

  public dropinStateSource: BehaviorSubject<string> = new BehaviorSubject<string>(AdyenSessionErrorType.DROP_IN_WAITING);
  public dropinState$: Observable<string> = this.dropinStateSource.asObservable();

  checkoutConfigModel: AdyenCheckoutConfigurationModel = <AdyenCheckoutConfigurationModel>{};
  checkoutUrl: string;

  constructor(private paymentService: PaymentService) {}

  build(session: AdyenSessionResponse, checkoutUrl: string) {
    this.checkoutConfigModel.clientKey = session.clientKey;
    this.checkoutConfigModel.environment = session.environment.toLowerCase();
    this.checkoutConfigModel.session = {
      id: session.sessionId,
      sessionData: session.sessionData
    };
    this.checkoutConfigModel.paymentMethodsConfiguration = this.onPaymentMethodsConfiguration;

    this.checkoutUrl = checkoutUrl;
    this.checkoutConfigModel.onPaymentCompleted = this.onPaymentCompletedFunction;
    this.checkoutConfigModel.onError = this.onErrorFunction;
  }

  get onPaymentCompletedFunction() {
    return (result: any , component: any) : void => {
      this.handleAdyenResult(result.resultCode);
    };
  }

  get onErrorFunction() {
    return (error: Error, component: any) : void => {
      console.error(error.name, error.message, error.stack, component);
      this.dropinStateSource.next(AdyenSessionErrorType.DROP_IN_GENERAL_ERROR);
      this.paymentService.remountDropin().then();
    };
  }

  get onPaymentMethodsConfiguration() {
    return {
      card: {
        billingAddressRequired: false,
        hasHolderName: true,
        holderNameRequired: true,
        styles: {
          base: {color: '#000000'},
          error: {color: '#000000'},
          placeholder: {color: '#0000005C'}
        }
      }
    };
  }

  private handleAdyenResult(resultCode: string) {
    switch (resultCode) {
      case 'Authorised':
        window.location.href = this.checkoutUrl;
        this.dropinStateSource.next(AdyenSessionErrorType.DROP_IN_SUCCESS);
        break;
      case 'Pending':
      case 'Received':
        break;
      case 'Refused':
        this.dropinStateSource.next(AdyenSessionErrorType.DROP_IN_TRANSACTION_REFUSED);
        this.paymentService.remountDropin().then();
        break;
      default:
        this.dropinStateSource.next(AdyenSessionErrorType.DROP_IN_GENERAL_ERROR);
        this.paymentService.remountDropin().then();
    }
  }
}

interface AdyenCheckoutConfigurationModel extends CoreOptions {
  onPaymentCompleted: (result: any , component: any) => void;
  onError: (error: Error, component: any) => void;
}
