import {Injectable} from '@angular/core';
import {ButlerConfiguration} from '../models/ButlerConfiguration';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AutoCompleteItem} from '../models/AutoCompleteItem';
import {TranslateService} from '@ngx-translate/core';
import { CreateTripResponse } from '../models/CreateTripResponse';
import { EtaResponse } from '../models/EtaResponse';
import {LegalDocumentType} from '../models/LegalDocumentType';
import {EstimationResponse} from '../models/EstimationResponse';
import {ThemeDetails} from '../models/ThemeDetails';
import {ThemeService} from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private qrId: number | undefined;
  private hash: string | undefined;
  private termsOfUseChecksum: string | undefined;
  private privacyPolicy: string | undefined;

  private readonly baseUrl = '/butler/qr/1';
  private readonly paymentUrl = '/payment/1/payment';

  private butlerConfigurationSource: BehaviorSubject<ButlerConfiguration | undefined> = new BehaviorSubject<ButlerConfiguration | undefined>(undefined);
  public butlerConfiguration$: Observable<ButlerConfiguration | undefined> = this.butlerConfigurationSource.asObservable();

  private destinationSource: BehaviorSubject<AutoCompleteItem[] | string> = new BehaviorSubject<AutoCompleteItem[] | string>([]);
  public destination$: Observable<AutoCompleteItem[] | string> = this.destinationSource.asObservable();

  public etaForPickupSource: BehaviorSubject<EtaResponse | undefined> = new BehaviorSubject<EtaResponse | undefined>(undefined);
  public etaForPickup$: Observable<EtaResponse | undefined> = this.etaForPickupSource.asObservable();

  public selectedLanguageSource: BehaviorSubject<string> = new BehaviorSubject('en');
  public selectedLanguage$: Observable<string> = this.selectedLanguageSource.asObservable();

  constructor(private httpClient: HttpClient, private translateService: TranslateService,private themeService: ThemeService) {
  }

  public getConfiguration(qrId: number, hash: string): Promise<boolean> {
    const url = `${this.baseUrl}/${qrId}/details`;
    const params = {k: hash};

    return new Promise<boolean>(resolve => {
      this.httpClient
        .get<ButlerConfiguration>(url, {params})
        .subscribe({
          next: (configuration) => {
            this.butlerConfigurationSource.next(configuration);
            this.qrId = qrId;
            this.hash = hash;
            resolve(true);
          },
          error: () => {
            resolve(false);
          }
        });
    });
  }

  createTrip(bookingDetails: any): Promise<CreateTripResponse> {
    const url = `${this.baseUrl}/${this.qrId}/trip`;
    const params = {k: this.hash!};
    const body = {
      ...bookingDetails,
      lang: this.translateService.currentLang,
      termsOfUseChecksum: this.termsOfUseChecksum,
      privacyPolicyChecksum: this.privacyPolicy
    };

    return new Promise<CreateTripResponse>((resolve, reject) => {
      this.httpClient
        .post<CreateTripResponse>(url, body, {params})
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error)
        });
    });
  }

  createSmsTrip(bookingDetails: any): Promise<CreateTripResponse> {
    const url = `${this.baseUrl}/${this.qrId}/smsTrip`;
    const params = {k: this.hash!};
    const body = {
      ...bookingDetails,
      lang: this.translateService.currentLang,
      termsOfUseChecksum: this.termsOfUseChecksum,
      privacyPolicyChecksum: this.privacyPolicy
    };

    return new Promise<CreateTripResponse>((resolve, reject) => {
      this.httpClient
        .post<CreateTripResponse>(url, body, {params})
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error)
        });
    });
  }

  createPaidTrip(bookingDetails: any): Promise<CreateTripResponse> {
    const url = `${this.baseUrl}/${this.qrId}/paidTrip`;
    const params = {k: this.hash!};
    const body = {
      ...bookingDetails,
      lang: this.translateService.currentLang,
      termsOfUseChecksum: this.termsOfUseChecksum,
      privacyPolicyChecksum: this.privacyPolicy
    };

    return new Promise<CreateTripResponse>((resolve, reject) => {
      this.httpClient
        .post<CreateTripResponse>(url, body, {params})
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error)
        });
    });
  }

  confirmSmsTrip(tripId: number, code: string): Promise<CreateTripResponse> {
    const url = `${this.baseUrl}/${this.qrId}/smsTrip/${tripId}`;
    const params = {k: this.hash!, code: code};
    const body = null;

    return new Promise<CreateTripResponse>((resolve, reject) => {
      this.httpClient
        .post<CreateTripResponse>(url, body, {params})
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error)
        });
    });
  }

  public calculatePriceEstimation(bookingDetailsCopy: any): Observable<EstimationResponse> {
    const url = `${this.baseUrl}/${this.qrId}/price`;
    const params = {k: this.hash!};

    return this.httpClient
      .post<any>(url, bookingDetailsCopy, {params});
  }

  public calculateETAForPickup(bookingDetails: any): void {
    if(bookingDetails.destination && !bookingDetails.reservationTime) {
      const url = `${this.baseUrl}/${this.qrId}/eta`;
      const params = {k: this.hash!};

      this.httpClient
        .post<EtaResponse>(url, bookingDetails, {params})
        .subscribe({
          next: (result) => {
            this.etaForPickupSource.next(result);
          },
          error: () => {
            this.etaForPickupSource.next(undefined);
          }
        });
    } else {
      this.etaForPickupSource.next(undefined);
    }
  }

  public searchDestination(searchTerm: string, language: string): void {
    const url = `${this.baseUrl}/${this.qrId}/auto-complete`;
    const body = {
      fieldName: 'destination',
      value: searchTerm,
      deviceLanguage: language
    };
    const params = {k: this.hash!};

    this.httpClient
      .post<any>(url, body, {params}) // todo -> replace any for an interface!!!
      .subscribe({
        next: (result) => {
          if (result.items && result.items.length > 0) {
            this.destinationSource.next(result.items);
          } else {
            this.destinationSource.next('NO_AUTO_COMPLETE_RESULTS');
          }
        },
        error: () => this.destinationSource.next('NO_AUTO_COMPLETE_RESULTS')
      });
  }

  public validateDestination(destination: AutoCompleteItem): Observable<any> { // todo replace for an interface
    const url = `${this.baseUrl}/${this.qrId}/auto-complete/validate`;
    const body = {
      fieldName: 'destination',
      item: destination
    };
    const params = {k: this.hash!};

    return this.httpClient
      .post<any>(url, body, {params});
  }

  public getCookieNoticeDocument(): any {
    const url = `${this.baseUrl}/${this.qrId}/cookie-notice`;
    const params = {k: this.hash!};
    return this.httpClient.get(url, {params: params, responseType: 'blob', observe: 'response'});
  }

  public getThemeConfiguration(tripId: string, key: string): Promise<boolean> {
    const url = `${this.baseUrl}/${tripId}/theme`;
    const params = {k: key!};
    return new Promise<boolean>((resolve) => {
      this.httpClient
        .get<ThemeDetails>(url, {params})
        .subscribe({
          next: (themeConfig) => {
            this.themeService.updateTheme(themeConfig);
            resolve(true);
          },
          error: () => {
            resolve(false);
          }
        });
    });
  }

  public sendInvoiceToEmail(tripId: string | null, email: string, hash: string | null): Promise<boolean> {
    const url = `${this.paymentUrl}/sendInvoice`;
    const body = {
      tripId: tripId,
      email: email
    };
    const params = {k: hash!};

    return new Promise<boolean>((resolve, reject) => {
      this.httpClient
        .put<boolean>(url, body, {params})
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error)
        });
    });
  }

  public parseKey(key: string): any {
    const result: any = {};
    const pairs = key.split('|');
    for (const pair of pairs) {
      const split = pair.split(':');
      result[split[0]] = split.length > 1 ? split[1] : null;
    }
    return result;
  }

  public resetDestinationResults(): void {
    this.destinationSource.next([]);
  }
}
