import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../../services/theme.service';
import {Subscription} from 'rxjs';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-payment-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  private configurationSubscription!: Subscription;

  public darkMode: boolean | undefined;

  constructor(private apiService: ApiService,
              private themeService: ThemeService) {  }

  ngOnInit(): void {
    this.configurationSubscription = this.apiService.butlerConfiguration$
      .subscribe(config => {
        if (config) {
          this.themeService.updateTheme(config.themeDetails);
        }
      });
  }
}
