import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {

  private consentedToCookies = true;


  constructor(private cookieService: CookieService) { }

  public setTrackingConsent(consented: boolean): void {
    this.consentedToCookies = consented;
  }

  public getTrackingConsent(): boolean {
    return this.cookieService.get('acceptedCookies') === 'all';
  }
}
