import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LegalDocumentType} from '../models/LegalDocumentType';

@Injectable({
  providedIn: 'root'
})
export class LegalService {

  private confirmedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isConfirmed$: Observable<boolean> = this.confirmedSource.asObservable();

  private viewLegalOpenSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public viewLegalOpen$: Observable<boolean> = this.viewLegalOpenSource.asObservable();

  private legalDocumentTypeSource: BehaviorSubject<LegalDocumentType> = new BehaviorSubject<LegalDocumentType>(LegalDocumentType.TERMS_OF_USE);
  public legalDocumentType$: Observable<LegalDocumentType> = this.legalDocumentTypeSource.asObservable();


  constructor() { }

  public setLegalOpen(isOpen: boolean): void {
    this.viewLegalOpenSource.next(isOpen);
  }

  public setConfirmed(value: boolean): void {
    this.confirmedSource.next(value);
  }

  public setLegalDocumentType(documentType: LegalDocumentType) {
    this.legalDocumentTypeSource.next(documentType);
  }
}
