<div #container class="map-container">
  <google-map id="map" #map height="100%" *ngIf="displayMap" width="100%" [hidden]="!configuration" [options]="options">
    <map-marker *ngIf="pickup"
                [position]="pickup"
                [icon]="createIcon('pickup')"></map-marker>

    <map-marker *ngIf="destination"
                [position]="destination"
                [icon]="createIcon('destination')"></map-marker>

    <div id="content" [ngStyle]="{'background-color' : (themeDetails.theme === 'DARK') ? '#3d3d3d' : 'white'}">
      <div *ngIf="timeAtPickup" [ngClass]="[themeDetails.theme === 'DARK' ? 'min-tooltip-dark' : 'min-tooltip-light', 'min-tooltip']">
        {{timeAtPickup}}
      </div>
      <div [ngClass]="[themeDetails.theme === 'DARK' ? 'venue-tooltip-dark' : 'venue-tooltip-light', 'venue-tooltip']">
        {{venueName}}
      </div>
    </div>

    <div id="content-des" [ngStyle]="{'background-color' : (themeDetails.theme === 'DARK') ? '#3d3d3d' : 'white'}">
      <div *ngIf="timeAtDestination" [ngClass]="[themeDetails.theme === 'DARK' ? 'min-tooltip-dark' : 'min-tooltip-light', 'min-tooltip']">
        {{timeAtDestination}}
      </div>
      <div *ngIf="destinationName" [ngClass]="[themeDetails.theme === 'DARK' ? 'venue-tooltip-dark' : 'venue-tooltip-light', 'venue-tooltip']">
        {{this.destinationName}}
      </div>
    </div>
  </google-map>
</div>

