import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SelectCountryService } from './select-country.service';
import { ApiService } from './api.service';
import { ThemeService } from './theme.service';
import { LegalService } from './legal.service';

@Injectable({
  providedIn: 'root'
})

export class StepperService implements OnDestroy{
  private indexSource: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  public index$: Observable<number> = this.indexSource.asObservable();

  private bookingDetailsSource: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public bookingDetails$: Observable<any> = this.bookingDetailsSource.asObservable();


  private amountOfSteps = 0;
  private countrySub?: Subscription;
  private themeSub?: Subscription;


  private isOpenLocal: boolean = false;
  private isLegalOpenLocal: boolean = false;
  private panelClass: string = '';
  private viewLegalOpenSub!: Subscription;

  constructor(private dialog: MatDialog,
              private countrySelectService: SelectCountryService,
              private apiService: ApiService,
              private themeService: ThemeService,
              private legalService: LegalService
              ) {
    this.countrySub = this.countrySelectService.selectCountryOpen$.subscribe(isOpen => {
      this.isOpenLocal = isOpen;
    });
    this.viewLegalOpenSub = this.legalService.viewLegalOpen$.subscribe(open => this.isLegalOpenLocal = open);

    this.themeSub = this.themeService.isDarkTheme$.subscribe(isDark => {
      if (isDark) {
        this.panelClass = 'dark-dialog';
      } else {
        this.panelClass = 'light-dialog';
      }
    });
  }

  public setAmountOfSteps(amountOfSteps: number): void {
    this.amountOfSteps = amountOfSteps;
  }

  public previous(): void {
    if (this.isOpenLocal || this.isLegalOpenLocal) {
      this.countrySelectService.setSelectCountryOpen(false);
      this.legalService.setLegalOpen(false);
    } else if (this.indexSource.value - 1 > -1) {
      this.indexSource.next(this.indexSource.value - 1);
    } else if(Object.keys(this.bookingDetailsSource.value).length === 0){
      this.reset();
    } else {
      const ref = this.dialog.open(ConfirmationDialogComponent,
        {
          panelClass: this.panelClass,
          data: {title: 'LEAVE_BOOKING', message: 'LEAVE_BOOKING_TEXT', confirmText: 'LEAVE', cancelText: 'CANCEL'},
          maxWidth: window.innerWidth < 600 ? '90%' : '500px',
        });
      ref.afterClosed().subscribe(result => {
        if (result) {
          this.reset();
        }
      });
    }
  }

  public next(): void {
    this.updateLocalStorageData();

    if (this.indexSource.value + 1 < this.amountOfSteps) {
      this.indexSource.next(this.indexSource.value + 1);
    }

    if (this.indexSource.value == 1) {
      this.apiService.resetDestinationResults();
    }
  }

  public updateBookingDetails(bookingDetails: any): void {
    this.bookingDetailsSource.next(bookingDetails);
  }

  public jumpToBookingOverview(): void {
    this.indexSource.next(2); // todo: update once the booking overview is merged
  }

  private updateLocalStorageData() {
    let bookingDetails = this.bookingDetailsSource.value;
    bookingDetails.expirationDate = new Date().getTime() + 10 * 60 * 1000; // object expires after 10 minutes;
    localStorage.setItem('bookingDetails', JSON.stringify(this.bookingDetailsSource.value));
  }

  public reset(): void {
    this.indexSource.next(-1);
    this.bookingDetailsSource.next({});
    this.apiService.resetDestinationResults();
  }

  ngOnDestroy(): void {
    this.countrySub?.unsubscribe();
    this.viewLegalOpenSub?.unsubscribe();
  }
}
