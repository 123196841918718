import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdyenSessionResponse } from '../models/AdyenSessionResponse';
import { CreateTripResponse } from '../models/CreateTripResponse';

@Injectable({
  providedIn: 'root'
})
export class AdyenSessionService {

  private readonly baseUrl = '/payment/1/payment';

  constructor(private httpClient: HttpClient) { }

  public getSessionConfiguration(tripResponse: CreateTripResponse): Promise<AdyenSessionResponse> {
    const url = `${this.baseUrl}`;
    const params = {
      tripId: tripResponse.tripId.toString(),
      hash: tripResponse.hash
    };
    return new Promise<AdyenSessionResponse>(resolve => {
      this.httpClient
        .post<AdyenSessionResponse>(url, null, {params})
        .subscribe({
          next: (configuration) => {
            resolve(configuration);
          }, error: (e) => {
            throw Error(e);
          }
        });
    });
  }
}
