import {CookieConsentService} from './cookie-consent.service';
import {Injectable} from '@angular/core';

declare let gtag:Function;

@Injectable()
export class GoogleAnalyticsService {

  private events: String[] = [];

  constructor(private cookieConsentService: CookieConsentService) {
  }

  public sendEvent(eventName: string, parameter: string) {
    if (this.cookieConsentService.getTrackingConsent()) {
      if (!this.events.includes(eventName)) {
        gtag('event', eventName, {
          succes: parameter,
        });
        this.events.push(eventName);
      }
    }
  }
}
