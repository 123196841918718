import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import Countries from '../../models/constants/Countries';
import {SelectCountryService} from '../../services/select-country.service';
import {Country} from '../../models/Country';
import {ThemeService} from '../../services/theme.service';
import {ApiService} from '../../services/api.service';
import {Subscription} from 'rxjs';
import {PhoneNumberUtil} from 'google-libphonenumber';

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss']
})
export class SelectCountryComponent implements OnInit, OnDestroy {
  private themeSubscription!: Subscription;
  private apiSub!: Subscription;

  isScrollDisabled = false;
  letterGroups: Array<string> = [];
  groups: Array<any> = [];

  public countries = Countries();
  public searchStarted: boolean = false;
  public darkMode: boolean = true;

  public commonCountries: Country[] = [];
  public isSmallScreen: boolean = window.innerWidth < 600;
  private phoneUtil!: PhoneNumberUtil;

  constructor(public selectCountryService: SelectCountryService,
              private themeService: ThemeService,
              private apiService: ApiService) {}

  ngOnInit(): void {
    this.getCountries();
    this.getCommonCountries();
    this.phoneUtil = PhoneNumberUtil.getInstance();
    this.themeSubscription = this.themeService.isDarkTheme$.subscribe(darkMode => this.darkMode = darkMode);
  }

  ngOnDestroy(): void {
    this.apiSub.unsubscribe();
    this.themeSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isSmallScreen = window.innerWidth < 600;
  }

  private getCountries(): void {
    this.selectCountryService.listOfCountries$.subscribe(list => {
      this.countries = list;
      this.groupByCountryName(list);
      this.searchStarted = Countries().length !== list.length;
    });
  }

  private getCommonCountries(): void {
    this.apiSub = this.apiService.butlerConfiguration$.subscribe(conf => {
      this.commonCountries = [];
      const listOfCommonCountries = conf?.usageDataResponse!.phoneNumberCountries;
      if (listOfCommonCountries!.length! > 0) {
        listOfCommonCountries?.forEach(countryCode => {
          const country: Country = this.countries.filter(obj => obj.countryCode.toLowerCase() === countryCode.toLowerCase())[0];
          this.commonCountries.push(country);
        });
      }
    });
  }

  public getCountryPhoneCode(countryCode: string): number {
    return this.phoneUtil.getCountryCodeForRegion(countryCode);
  }

  getGroupId(letter: string): string {
    return `alphabet-scroll-${letter}`;
  }

  goToLetterGroup(letter: string): void {
    this.isScrollDisabled = true;
    let elementId = `alphabet-scroll-${letter}`;
    let element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView();
    }
  }

  enableScroll(): void {
    this.isScrollDisabled = false;
  }

  groupByCountryName(names: Array<Country>): void {
    this.groups = [];
    this.letterGroups = [];

    this.letterGroups = [];
    let sortedNames = names;
    let currentLetter: string;
    let currentCountries = [];

    sortedNames.forEach((country) => {
      const firstLetter = this.selectCountryService.getCountryTranslation(country.countryCode).charAt(0);
      if (firstLetter != currentLetter) {
        currentLetter = firstLetter;
        this.letterGroups.push(currentLetter);

        let newGroup = {
          letterGroup: currentLetter,
          Countries: [],
        };

        currentCountries = newGroup.Countries;
        this.groups.push(newGroup);
      }

      currentCountries.push(country);
    });
  }

  public selectCountry(country: Country): void {
    this.selectCountryService.setSelectCountry(country);
    this.selectCountryService.setSelectCountryOpen(false);
    this.selectCountryService.setSearchOpen(false);
    this.selectCountryService.resetSearch();
  }

}
