import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleHelperService {

  constructor(private title: Title) {
    title.setTitle(this.getTitleText());
  }

  getTitleText(): string {
    const url = window.location.hostname;

    if (url.includes('venuebutler')) {
      return 'Venue Butler QR';
    } else if (url.includes('localhost')) {
      return 'local QR';
    } else if (url.includes('test')) {
      return 'test QR';
    } else if (url.includes('dev')) {
      return 'dev QR';
    }else if (url.includes('staging')) {
      return 'staging QR';
    } else {
      return 'Taxi Butler QR';
    }
  }
}
