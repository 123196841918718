import {Component, HostBinding, Inject, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ThemeService} from './services/theme.service';
import {Theme} from './models/Theme';
import {AuthGuardService} from './services/auth-guard.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  private themeSubscription!: Subscription;
  private authGuardSubscription!: Subscription;

  constructor(private sanitizer: DomSanitizer,
              private router: Router,
              private titleService: Title,
              @Inject(DOCUMENT) private document: Document,
              private authGuardService: AuthGuardService,
              private themeService: ThemeService) { }

  ngOnInit(): void {
    this.themeSubscription = this.themeService.themeDetails$.subscribe(themeDetails => {
      this.isDark = themeDetails.theme === Theme.DARK.toString();
      this.proColor = themeDetails.primaryColor;
      this.proColorOpacity = themeDetails.primaryColor + '40';
      this.proColorSelectedRide = themeDetails.primaryColor + '0A';
    });
    this.authGuardSubscription = this.authGuardService.loading$.subscribe(loading => this.loading = loading);
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
    this.authGuardSubscription.unsubscribe();
  }

  @HostBinding('style.--pro-color')
  proColor = '#ffcc00'; // todo -> replace default

  @HostBinding('style.--pro-color-opacity')
  proColorOpacity = this.proColor + '40';

  @HostBinding('style.--pro-color-selected-ride')
  proColorSelectedRide = this.proColor + '0A';

  isDark = false;
  loading = false;

  @HostBinding('class')
  public get themeMode() {
    return this.isDark ? 'dark-theme' : 'light-theme';
  }
}
