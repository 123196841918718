import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';
import { CookieSettingsService } from '../../services/cookie-settings.service';

@Component({
  selector: 'app-cookie-toast',
  templateUrl: './cookie-toast.component.html',
  styleUrls: ['./cookie-toast.component.scss']
})
export class CookieToastComponent implements OnInit, OnDestroy {
  public isSmallScreen: boolean = true;
  isDarkMode: boolean = true;
  private darkModeSubscription: Subscription | undefined;

  constructor(private toastService: ToastService,
              private themeService: ThemeService,
              private cookieSettingsService: CookieSettingsService) {
  }

  ngOnInit(): void {
    this.toastService.setCookieToastVisible(this.cookieSettingsService.cookieDoesNotExists());
    this.isSmallScreen = window.innerWidth < 600;
    this.darkModeSubscription = this.themeService.isDarkTheme$.subscribe(darkMode => this.isDarkMode = darkMode);
  }

  public acceptAllCookies(): void {
    this.cookieSettingsService.setCookiesType('all');
    location.reload();
    this.toastService.setCookieToastVisible(false);
  }

  public acceptFunctionalCookies(): void {
    this.cookieSettingsService.setCookiesType('functional');
    this.toastService.setCookieToastVisible(false);
  }

  ngOnDestroy(): void {
    this.darkModeSubscription?.unsubscribe();
  }
}
