<div class="container" [ngClass]="darkMode ? 'dark' : 'light'">
  <div class="outer-div" [ngClass]="darkMode ? 'dark' : 'light'">
    <div class="loading" *ngIf="onWaitingStage()">
      <mat-spinner [diameter]="48" class="spinner"></mat-spinner>
      <h6> {{ 'PROCESSING' | translate }} </h6>
      <div> {{ 'PROCESSING_TEXT' | translate }}</div>
    </div>

    <div class="approved" *ngIf="onSuccessStage()">
      <img class="check" width="80" height="80" src="assets/icons/pay/check_circle.svg" alt="">
      <h6> {{ 'PAYMENT_COMPLETE' | translate }}  </h6>
      <div *ngIf="!reservationTime"> {{ 'PAYMENT_COMPLETE_TEXT' | translate }}</div>
      <div *ngIf="reservationTime"> {{ 'PAYMENT_COMPLETE_TEXT_RESERVATION' | translate: {reservationTime: reservationTime} }}</div>
      <div class="timer">
        <div> {{ 'MBT_TIMER_TEXT' | translate: {seconds: timeLeft} }} </div>

      </div>
    </div>

    <div class="not-approved" *ngIf="onFailedStage()">
      <img class="check" width="80" height="80" src="assets/icons/pay/warning.svg" alt="">
      <h6> {{ 'PAYMENT_FAILED'| translate }}</h6>
      <div> {{ 'PAYMENT_FAILED_TEXT'| translate }} </div>
    </div>
  </div>

  <button *ngIf="!onWaitingStage()" mat-button class="floating-button payment-button"
          [ngStyle]="{'color': darkMode ? 'black' : 'white'}" (click)="handleResult()">
    <div class="plan-ride-button">
      <div *ngIf="onFailedStage()" class="payment-btn-text">{{ 'TRY_AGAIN' | translate }}</div>
      <div *ngIf="onSuccessStage()" class="payment-btn-text">{{ 'BOOKING_TRACKER' | translate }}</div>
    </div>
  </button>
</div>
