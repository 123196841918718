import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public cookieToastVisible = true;

  private cookieToastVisibleSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.cookieToastVisible);
  public cookieToastVisible$: Observable<boolean> = this.cookieToastVisibleSource.asObservable();

  constructor() { }

  public setCookieToastVisible(visible: boolean): void {
    this.cookieToastVisibleSource.next(visible);
  }
}
