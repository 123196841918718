import { style, animate, trigger, transition, state } from '@angular/animations';

export const pulsatingButtonsStates = ['void', 'default', 'expansion'];
export const pulsatingButton =
    trigger('pulsatingButton', [
        state('default', style({
            padding: '0px',
            margin: '12px',
            borderRadius: '999px', // workaround to have it rounded
            backgroundColor: 'var(--pro-color)'
          })),
          state('expansion', style({
            padding: '12px',
            margin: '0px',
            borderRadius: '999px', // workaround to have it rounded
            backgroundColor: 'transparent'
          })),
          state('void', style({})),
        state('void', style({})),
        transition('* => default', [
          animate('0s')
        ]),
        transition('* => expansion', [
          animate('0.7s')
        ]),
        transition('* => void', [
          animate('1s')
        ]),
]);

export const getCurrentState = function(counter: number): string {
  return pulsatingButtonsStates[counter % 3];
};

