<mat-card [ngStyle]="{'height': screenHeight > 0 ? (screenHeight + 60 + 'px') : 'calc(100% - 32px)'}"
          class="vehicle-type-card">
  <div class="whats-ride">  {{ "WHATS_RIDE" | translate }}
  </div>
  <div [ngStyle]="{'height': screenHeight > 0 ? (screenHeight - 54 + 'px') : 'calc(100% - 104px );', 'overflow-y' : 'scroll'}"
       class="vehicle-type-wrapper">
    <div *ngFor="let vehicleType of vehicleTypes" [ngClass]="{'selected-ride': vehicleType === selectedVehicleType, 'disabled-ride': vehicleType.disabled === true }"
         class="ride-details"
         (click)="selectVehicleType(vehicleType)">
      <div class="ride-info">
        <img src="{{themeService.getVehicleIcon(vehicleType.icon)}}" alt="" class="ride-info-image">
        <div class="ride-info-text">
          <h6>{{vehicleType.label}}</h6>
          <p>{{vehicleType.description}}</p>
        </div>
      </div>
      <h6 *ngIf="vehicleType.calculatingPrice">
        <mat-spinner [diameter]="25"></mat-spinner>
      </h6>
      <h6 *ngIf="vehicleType.estimatedFullPrice && !vehicleType.calculatingPrice"
          style="white-space: nowrap;">{{vehicleType.estimatedFullPrice}}</h6>
    </div>
  </div>
  <div *ngIf="!selectedVehicleType" class="footer-spinner" [ngClass]="isDarkMode ? 'dark' : 'light'">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div *ngIf="!calculatingPriceEstimation()" class="footer-buttons">
    <div *ngIf="selectedVehicleType" [@pulsatingButton]="getCurrentState()" (@pulsatingButton.done)="nextState()"
         class="vehicle-type-buttons">
      <button *ngIf="!pickupTime" mat-button class="floating-button" (click)="confirmVehicleType()">
        <div class="plan-ride-button">
          <div class="plan-ride-text">{{'SELECT_RIDE' | translate: {carType: selectedVehicleType!.label} }}</div>
          <div class="date-text">{{'ASAP_FULL' | translate}}</div>
        </div>
      </button>
      <button *ngIf="pickupTime" mat-button class="floating-button" (click)="confirmVehicleType()">
        <div class="plan-ride-button">
          <div class="plan-ride-text">{{'SELECT_RIDE' | translate: {carType: selectedVehicleType!.label} }}</div>
          <div class="date-text">{{'FOR_DATE' | translate}} {{ this.pickupTime.value | date: 'dd MMM, HH:mm' }}</div>
        </div>
      </button>
    </div>
    <div *ngIf="!selectedVehicleType && priceEstimationRequired" class="vehicle-type-buttons">
      <button mat-button class="floating-button" disabled>
        <div class="plan-ride-button">
          <div class="plan-ride-text">{{'CAR_NOT_AVAILABLE' | translate}}</div>
        </div>
      </button>
    </div>
    <button mat-fab class="mat-elevation-z0" [ngClass]="[isDarkMode ? 'set-pickup-time-button-dark' : 'set-pickup-time-button-light']"
            *ngIf="futureBookingEnabled && selectedVehicleType" (click)="openBottomSheet()">
      <mat-icon [ngStyle]="{'color': isDarkMode ? 'black' : 'white'}">watch_later</mat-icon>
    </button>
  </div>
</mat-card>
