import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {debounceTime, distinctUntilChanged, fromEvent, map} from 'rxjs';
import {ApiService} from '../../../services/api.service';
import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'app-where-to-card',
  templateUrl: './where-to-card.component.html',
  styleUrls: ['./where-to-card.component.scss']
})

export class WhereToCardComponent implements OnInit, AfterViewInit {

  @Input() setFocus: boolean = false;
  public searchValue = '';

  @ViewChild('destinationSearchInput', { static: true }) destinationSearchInput?: ElementRef;

  constructor(private apiService: ApiService,
              private cookieService: CookieService) {}

  ngOnInit(): void {
    this.handleSearchInputs();
  }

  ngAfterViewInit(): void {
    this.onLanguageChange();
    this.onDestinationFocus();
    this.onDestinationScrollTop();
  }

  private handleSearchInputs(): void {
    fromEvent(this.destinationSearchInput?.nativeElement, 'keyup').pipe(
      map((event: any) => {
        this.searchValue = event.target.value;
        return event.target.value;
      }),
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe((searchTerm: string) => {
      if (searchTerm.length < 3) {
        this.apiService.resetDestinationResults();
      } else {
        let language = this.cookieService.get('selectedLanguage');
        this.apiService.searchDestination(searchTerm, language);
      }
    });
  }

  public onDestinationFocus() {
    if(this.setFocus) {
      this.destinationSearchInput?.nativeElement.focus();
    }
  }

  clearInput(): void {
    this.searchValue = '';
    this.apiService.resetDestinationResults();
    this.destinationSearchInput!.nativeElement.value = '';
  }

  private onLanguageChange(): void {
    this.apiService.selectedLanguage$.subscribe(language => {
      let destinationInput = this.destinationSearchInput?.nativeElement.value;
      if(destinationInput) {
        this.apiService.searchDestination(destinationInput, language);
      }
    });
  }

  private onDestinationScrollTop(): void {
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }, 250);
  }
}


