<div class="select-country-container">
    <div *ngIf="!searchStarted && commonCountries.length > 0">
      <div [ngClass]="[darkMode ? 'letterbox-dark' : 'letterbox-light', 'letterbox']">
        <div class="country-header">
          {{'COMMON_COUNTRIES' | translate}}
        </div>
      </div>
      <div *ngFor="let country of commonCountries">
        <div class="country-box" [ngClass]="[darkMode ? 'container-dark' : 'container-light', 'container']" (click)="selectCountry(country)">
          <img class="img" src="/assets/icons/flags/{{country.countryCode}}.png">
          <div class="country-and-code">
            {{selectCountryService.getCountryTranslation(country.countryCode)}}
            <p class="phone-code">+{{getCountryPhoneCode(country.countryCode)}}</p>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  <div class="country" [id]="getGroupId(group.letterGroup)" *ngFor="let group of groups">
    <div [ngClass]="[darkMode ? 'letterbox-dark' : 'letterbox-light', 'letterbox']">
      <div class="country-header">
        {{group.letterGroup.toUpperCase()}}
      </div>
    </div>
    <div *ngFor="let country of group.Countries">
      <div class="country-box" [ngClass]="[darkMode ? 'container-dark' : 'container-light', 'container']" (click)="selectCountry(country)">
        <img class="img" src="/assets/icons/flags/{{country.countryCode}}.png">
        <div class="country-and-code">
          {{selectCountryService.getCountryTranslation(country.countryCode)}}
          <p class="phone-code">+{{getCountryPhoneCode(country.countryCode)}}</p>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
</div>

<alphabetical-scroll-bar *ngIf="isSmallScreen" class="alpha-scroll"
  [alphabet]="'ABCDEFGHIJKLMNOPQRSTUVWXYZ'"
  [overflowDivider]="'-'"
  [validLetters]="letterGroups"
  [disableInvalidLetters]="true"
  [prioritizeHidingInvalidLetters]="true"
  [letterMagnification]="false"
  [magnifyDividers]="false"
  [magnificationMultiplier]="1.4"
  [exactX]="false"
  [letterSpacing]="'0.5%'"
  [offsetSizeCheckInterval]="100"
  (letterChange)="goToLetterGroup($event)"
  (isActive)="!$event && enableScroll()"
></alphabetical-scroll-bar>
