<div class="main">
  <h6> {{ 'SERVICE_FEES_ESTIMATE' | translate }}</h6>

  <div> {{ 'SERVICE_FEES_TEXT' | translate: {dispatcherName: this.data.dispatcherName} }} </div>

  <div class="fees-container">
    <div> {{ 'BASE_SERVICE_FEE' | translate }} <div class="display-inline money" [ngStyle]="{'color': this.proColor}"> {{this.data.currency + ' ' + this.data.fixedServiceFee}} </div></div>

    <div *ngIf="+this.data.variableServiceFeePercentage >= 0" class="display-inline full">
      {{ 'VARIABLE_SERVICE_FEE' | translate }}
      <div class="light-gray display-inline">
         ({{this.data.variableServiceFeePercentage + '%'}} {{ 'OF_SERVICE_FEE' | translate }})
      </div>
      <div class="display-inline money" [ngStyle]="{'color': this.proColor}">{{this.data.currency + ' ' + this.data.variableServiceFee}}</div>
    </div>

  </div>

  <div> {{ 'BREAKDOWN_END_NOTE' | translate: {dispatcherName: this.data.dispatcherName} }}</div>

  <div class="btn-container">
    <button mat-button class="btn" [ngStyle]="{'color': this.darkMode ? 'black' : 'white' ,'background-color': this.proColor, 'text-transform': 'uppercase'}" (click)="closeDialog()">{{ 'CLOSE' | translate}}</button>
  </div>
</div>
