<mat-divider></mat-divider>
<mat-nav-list>
  <a mat-list-item [routerLink]="['']" [queryParamsHandling]="'preserve'" class="align"
     routerLinkActive="active-list-item">
    <mat-icon>home</mat-icon>
    <span class="nav-caption">{{ 'HOME' | translate }}</span>
  </a>
  <a *ngIf="complianceEnabled" mat-list-item [routerLink]="['document/terms-of-use']" [queryParamsHandling]="'preserve'" class="align"
     routerLinkActive="active-list-item">
    <mat-icon>gavel</mat-icon>
    <span class="nav-caption">{{ 'TERMS_OF_USE' | translate }}</span>
  </a>

  <a *ngIf="complianceEnabled" mat-list-item [routerLink]="['document/privacy-policy']" [queryParamsHandling]="'preserve'" class="align"
     routerLinkActive="active-list-item">
    <mat-icon>security</mat-icon>
    <span class="nav-caption">{{ 'PP' | translate }}</span>
  </a>

  <a  *ngIf="partnerUrl && complianceEnabled" mat-list-item [routerLink]="['document/dispatcher-terms']" [queryParamsHandling]="'preserve'" class="align"
     routerLinkActive="active-list-item">
        <mat-icon>handshake</mat-icon>
        <span class="nav-caption"> {{ partnerName }} {{ 'TERMS' | translate }}</span>
  </a>

  <a mat-list-item [routerLink]="['document/cookie-notice']" [queryParamsHandling]="'preserve'" class="align"
     routerLinkActive="active-list-item">
    <mat-icon>article</mat-icon>
    <span class="nav-caption">{{ 'CN' | translate }}</span>
  </a>

  <a mat-list-item [routerLink]="['cookies']" [queryParamsHandling]="'preserve'" class="align"
     routerLinkActive="active-list-item">
    <mat-icon>cookie</mat-icon>
    <span class="nav-caption">{{ 'CS' | translate }}</span>
  </a>
</mat-nav-list>
