<div class="container" [ngClass]="[darkMode ? 'dark-background' : 'light-background', 'shadow']">

  <div class="spinner">
    <mat-progress-spinner
      mode="determinate"
      [value]="progress"
      diameter="48"
    strokeWidth="4">
    </mat-progress-spinner>
    <mat-progress-spinner
      class="overlay-spinner"
      mode="determinate"
      value="100"
      diameter="44"
      strokeWidth="1"
    >
    </mat-progress-spinner>

    <p class="body-2">{{currentStep + 1 + ' of ' + steps.length}}</p>
  </div>

  <div class="steps-container">
    <div class="current-step">
      {{ 'STEP' | translate }} {{ currentStep + 1 }}: {{ this.getStepDescription(currentStep) | translate}}
    </div>
    <div class="next-step">
      {{ 'NEXT' | translate }}:
      {{(steps[currentStep + 1] ? this.getStepDescription(currentStep + 1) : 'ENJOY_YOUR_TRIP') | translate }}
    </div>
  </div>
</div>

