import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ButlerConfiguration} from '../../models/ButlerConfiguration';
import {ApiService} from '../../services/api.service';
import {ThemeService} from '../../services/theme.service';
import {StepperService} from '../../services/stepper.service';
import {GoogleMap} from '@angular/google-maps';
import {Theme} from '../../models/Theme';
import {ThemeDetails} from '../../models/ThemeDetails';
import { formatDate } from '@angular/common';
import {SvgIcons} from '../../models/constants/SvgIcons';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('container', {read: ElementRef}) elementContainerView: ElementRef | undefined;
  private configurationSubscription!: Subscription;
  private bookingDetailsSubscription!: Subscription;
  private themeDetailsSubscription!: Subscription;
  private stepperSubscription!: Subscription;
  private etaSubscription!: Subscription;

  private lightMap = '12385573562bf88e';
  private darkMap = 'f00e06a556eb3058';
  public themeDetails!: ThemeDetails;

  public screenHeight: any;
  public isSmallScreen = true;
  public displayMap = true;
  public configuration!: ButlerConfiguration;

  @ViewChild(GoogleMap) map!: GoogleMap;
  public animationTimeouts: any[] = [];
  public partials: google.maps.Polyline[] = [];
  public options: google.maps.MapOptions = {
    mapId: this.lightMap,
    minZoom: 5,
    maxZoom: 17,
    disableDefaultUI: true
  };
  public pickup: google.maps.LatLngLiteral = {lat: 0, lng: 0};
  public destination?: google.maps.LatLngLiteral;
  private bounds: google.maps.LatLngBounds | undefined;

  public venueName: string = '';
  public destinationName?: string | null = '';
  timeAtPickup: string | null = null;
  timeAtDestination: string | null = null;
  public index?: number;
  private etaRecheckTask: any;

  constructor(private apiService: ApiService,
              private themeService: ThemeService,
              private stepperService: StepperService) {}

  ngOnInit(): void {
    this.setHeights();
    this.setThemeSubscription();
    this.setStepperSubscription();
    this.setEtaSubscription();
  }

  private setEtaSubscription(): void {
    this.etaSubscription = this.apiService.etaForPickup$.subscribe(eta => {
      if (eta && eta.etaForPickupInMinutes != null && eta.etaForDropOffInMinutes != null) {
        let timeAtDropOff = new Date(new Date().getTime() + (eta.etaForPickupInMinutes + eta.etaForDropOffInMinutes) * 60 * 1000);
        this.timeAtPickup = `±${eta.etaForPickupInMinutes} mins`;
        this.timeAtDestination = `±${formatDate(timeAtDropOff, 'HH:mm', 'en-US')}`;
      } else {
        this.timeAtPickup = null;
        this.timeAtDestination = null;
      }
    });
  }

  private setStepperSubscription(): void {
    this.stepperSubscription = this.stepperService.index$.subscribe(index => {
      this.index = index;
      if (index == 0) {
        this.resetMap();
      }
    });
  }

  private setThemeSubscription(): void {
    this.themeDetailsSubscription = this.themeService.themeDetails$.subscribe(themeDetails => {
      this.themeDetails = themeDetails;
      if (themeDetails.theme === Theme.DARK.toString()) {
        this.options.mapId = this.darkMap;
      }
    });
  }

  ngOnDestroy(): void {
    this.configurationSubscription.unsubscribe();
    this.bookingDetailsSubscription.unsubscribe();
    this.themeDetailsSubscription.unsubscribe();
    this.stepperSubscription.unsubscribe();
    this.etaSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.configurationSubscription = this.apiService.butlerConfiguration$
      .subscribe(config => this.configurationChanged(config));
    this.bookingDetailsSubscription = this.stepperService.bookingDetails$
      .subscribe(details => this.bookingDetailsChanged(details));
    this.addControlUI();
    if (this.map) {
      const popup = new Popup(
        new google.maps.LatLng(this.pickup.lat, this.pickup.lng),
        document.getElementById('content') as HTMLElement
      );
      popup.setMap(this.map.googleMap!);
    }
  }

  public createIcon(type: string): any {
    let path: string;
    let scale: number;
    let anchor: any;

    switch (type) {
      case 'pickup':
        path = SvgIcons.PICKUP;
        scale = 1.2;
        anchor = new google.maps.Point(13, 22);
        break;
      case 'destination':
        path =  SvgIcons.DESTINATION;
        scale = 0.046;
        anchor = new google.maps.Point(250, 450);
        break;
      default:
        return null;
    }

    return {
      path: path,
      fillColor: this.themeDetails.primaryColor,
      fillOpacity: 1,
      anchor: anchor,
      strokeWeight: 0,
      scale: scale,
    };
  }

  private setHeights(): void {
    this.isSmallScreen = window.innerWidth < 600;
    this.screenHeight = window.innerHeight;
  }

  private resetMap(): void {
    this.map.data.forEach(feature => this.map.data.remove(feature)); // clear any old features
    this.partials.forEach(partial => partial.setMap(null)); // clearing the polylines and the timeouts
    this.partials = [];
    this.animationTimeouts.forEach(timeout => clearTimeout(timeout));

    this.pickup = {lat: this.configuration.venueDetails.latitude, lng: this.configuration.venueDetails.longitude};
    this.venueName = this.configuration?.venueDetails.name;

    this.destination = undefined;
    this.destinationName = undefined;

    this.bounds = new google.maps.LatLngBounds(this.pickup);
    this.map.panToBounds(this.bounds, 20);
    this.map.fitBounds(this.bounds, 20);
  }

  private configurationChanged(configuration: ButlerConfiguration | undefined): void {
      if (configuration) {
        this.configuration = configuration;
      }
  }

  private bookingDetailsChanged(bookingDetails: any): void {
    this.calculateTripTimes(bookingDetails);
    this.resetMap();
    this.parseDestination(bookingDetails);
    this.addDestinationToMap(bookingDetails);
  }

  private parseDestination(bookingDetails: any): void {
    if (bookingDetails.destination) {
      const key = this.apiService.parseKey(bookingDetails.destination.valueKey);
      this.destination = key.lat && key.lng ? {lat: Number(key.lat), lng: Number(key.lng)} : undefined;
      this.destinationName = bookingDetails.destination.displayValue;

      const popup = new Popup(
        new google.maps.LatLng(key.lat, key.lng),
        document.getElementById('content-des') as HTMLElement
      );
      popup.setMap(this.map.googleMap!);

    } else {
      this.destination = undefined;
    }
  }

  private addDestinationToMap(bookingDetails: any): void {
    if (this.destination) {
      this.bounds = new google.maps.LatLngBounds(this.pickup);
      this.bounds.extend(this.destination);

      if (bookingDetails.destination.route) {
        this.map.data.addGeoJson(bookingDetails.destination.route);
        this.map.data.setStyle(
          {
            strokeWeight: 5,
            strokeOpacity: .5,
            strokeColor: this.themeDetails.primaryColor
          });

        const route = bookingDetails.destination.route.features[0].geometry.coordinates;
        this.pickup = {lng: route[0][0], lat: route[0][1]};
        route.forEach((lngLat: any) => this.bounds!.extend({lng: lngLat[0], lat: lngLat[1]}));

        this.animateRoute(route);

        setTimeout(() => {
          if (this.bounds) {
            const padding = this.venueName.length > 10 ? 70 : 20;
            this.map.panToBounds(this.bounds, padding);
            this.map.fitBounds(this.bounds, padding);
          }
        }, 100);

      } else {
        this.map.panToBounds(this.bounds);
        this.map.fitBounds(this.bounds);
      }
    }
  }

  private addControlUI(): void {
    if (!this.map) return;

    const centerControlDiv = document.createElement('div');
    this.map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(centerControlDiv);

    const controlUI = document.createElement('div');
    controlUI.className = 'control-ui';
    controlUI.style.background = this.themeDetails.theme === Theme.LIGHT.toString() ? '#fff' : '#3B3B3B';
    controlUI.style.display = 'none';
    centerControlDiv.appendChild(controlUI);

    const controlText = document.createElement('div');

    controlText.className = 'icon';
    controlText.style.color = this.themeDetails.theme === Theme.LIGHT.toString() ? '#000' : '#fff';
    controlText.innerHTML = 'gps_fixed';
    controlUI.appendChild(controlText);

    controlUI.addEventListener('click', () => {
      controlUI.style.display = 'none';
      if (this.bounds) {
        this.map.panToBounds(this.bounds, 20);
        this.map.fitBounds(this.bounds, 20);
      } else {
        this.resetMap();
      }
    });

    google.maps.event.addListener(this.map.googleMap!, 'dragstart', () => {
      controlUI.style.display = 'block';
      controlUI.style.marginBottom = this.isSmallScreen ? this.destination ? '12px' : '160px' : '12px';
    });
  }

  private animateRoute(route: any): void {
    for (let i = 0; i < route.length - 1; i++) {
      const poly = new google.maps.Polyline({
        path: [{lng: route[i][0], lat: route[i][1]}, {lng: route[i + 1][0], lat: route[i + 1][1]}],
        strokeColor: this.themeDetails.primaryColor,
        strokeOpacity: 1,
        strokeWeight: 5,
      });
      poly.setVisible(false);
      poly.setMap(this.map.googleMap!);
      poly.setOptions({ zIndex: 100 });
      this.partials.push(poly);
    }
    this.routeDelayedLoop(0, route);
  }

  private routeFadeOut(opacity: number, route: any): void {
    this.animationTimeouts.push(setTimeout(() => {
      if (opacity < 0) {
        this.routeDelayedLoop(0, route);
      } else {
        this.partials.forEach(partial => partial.setOptions({strokeOpacity: opacity / 10}));
        this.routeFadeOut(opacity - 1, route);
      }
    }, 25));
  }

  private routeDelayedLoop(index: number, route: any): void {
    this.animationTimeouts.push(setTimeout(() => {
      if (index < this.partials.length) {
        this.partials[index].setVisible(true);
        this.partials[index].setOptions({strokeOpacity: 1});
        this.routeDelayedLoop(index + 1, route);
      } else {
        this.routeFadeOut(9, route);
      }
    }, 1000 / route.length));
  }

  private calculateTripTimes(bookingDetails: any): void {
    clearInterval(this.etaRecheckTask);
    this.apiService.calculateETAForPickup(bookingDetails);    // initial call with no delay
    this.etaRecheckTask = setInterval(() => {
      this.apiService.calculateETAForPickup(bookingDetails);
    }, 60 * 1000);
  }
}

class Popup extends google.maps.OverlayView {
  position: google.maps.LatLng;
  containerDiv: HTMLDivElement;

  constructor(position: google.maps.LatLng, content: HTMLElement) {
    super();
    this.position = position;

    content.classList.add('popup-bubble');

    // This zero-height div is positioned at the bottom of the bubble.
    const bubbleAnchor = document.createElement('div');

    bubbleAnchor.classList.add('popup-bubble-anchor');
    bubbleAnchor.appendChild(content);

    // This zero-height div is positioned at the bottom of the tip.
    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add(`popup-container`);
    this.containerDiv.appendChild(bubbleAnchor);

    // Optionally stop clicks, etc., from bubbling up to the map.
    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
  }

  /** Called when the popup is added to the map. */
  override onAdd(): void {
    this.getPanes()!.floatPane.appendChild(this.containerDiv);
  }

  /** Called when the popup is removed from the map. */
  override onRemove(): void {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  }

  /** Called each frame when the popup needs to draw itself. */
  override draw() {
    let divPosition = this.getProjection().fromLatLngToDivPixel(
      this.position
    )!;

    // Hide the popup when it is far out of view.
    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? 'block'
        : 'none';

    if (display === 'block') {
      this.containerDiv.style.left = divPosition.x + 'px';
      this.containerDiv.style.top = divPosition.y + 'px';
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }
}
