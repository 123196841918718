import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../services/payment.service';
import { FinalTripStates, TripStates } from '../../models/TripStates';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-waiting-payment',
  templateUrl: './waiting-payment.component.html',
  styleUrls: ['./waiting-payment.component.scss']
})
export class WaitingPaymentComponent implements OnInit {

  private paymentStage: any;
  private interval: any;

  public timeLeft: number = 20;
  public mbtURL: string | null = null;
  public reservationTime: string | null = null;
  darkMode: boolean = false;

  constructor(private paymentService: PaymentService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.darkMode = this.route.snapshot.queryParams['d']! === 'true';
    this.setStage(TripStates.PENDING);
    this.paymentService.updatePaymentStatus();
    this.paymentService.paymentState$.subscribe(state => {
      if (this.hasTripStarted(state) || state === TripStates.SUCCESSFUL) {
        this.setStage(TripStates.SUCCESSFUL);
        this.startTimer();
      } else if (state === TripStates.FAILED || state === TripStates.EXPIRED) {
        this.setStage(TripStates.FAILED);
      } else {
        this.setStage(TripStates.PENDING_PAYMENT);
      }
    });

    this.paymentService.mbtURL$.subscribe(mbtUrl => {
      if (mbtUrl) {
        this.mbtURL = mbtUrl;
      }
    });

    this.paymentService.reservationTime$.subscribe(reservationTime => {
      this.reservationTime = reservationTime;
    });
  }

  setStage(stage: TripStates): void {
    this.paymentStage = stage;
  }

  onWaitingStage(): boolean {
    return this.paymentStage === TripStates.PENDING_PAYMENT;
  }

  onSuccessStage(): boolean {
    return this.paymentStage === TripStates.SUCCESSFUL;
  }

  onFailedStage(): boolean {
    return this.paymentStage === TripStates.FAILED
      || this.paymentStage === TripStates.EXPIRED;
  }

  private hasTripStarted(state: TripStates): boolean {
    return Object.values(FinalTripStates()).includes(state);
  }

  public handleResult(): void {
    if (this.onSuccessStage()) {
      this.handleApproved();
    } else {
      this.handleTryAgain();
    }
  }

  private handleApproved(): void {
    clearInterval(this.interval);
    if (this.mbtURL) {
      window.location.href = this.mbtURL;
    }
  }

  private handleTryAgain(): void {
    this.router.navigate([''], {queryParams: {'t': null, retry: true}, queryParamsHandling: 'merge'});
  }

  private startTimer(): void {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.handleApproved();
      }
    }, 1000);
  }
}
