import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AutoCompleteItem} from '../../models/AutoCompleteItem';
import {ApiService} from '../../services/api.service';
import {Subscription} from 'rxjs';
import {ThemeService} from '../../services/theme.service';
import { GA4Events } from '../../models/constants/GA4Events';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-destination-results',
  templateUrl: './destination-results.component.html',
  styleUrls: ['./destination-results.component.scss']
})
export class DestinationResultsComponent implements OnInit, OnDestroy {
  private destinationSubscription!: Subscription;
  private themeSubscription!: Subscription;

  @Input() confirmDestination: (destination: AutoCompleteItem) => void = () => {};
  @Input() screenHeight: number = 0;

  public destinations: AutoCompleteItem[] = [];
  public displayText: string | undefined;
  public darkMode!: boolean;

  constructor(private apiService: ApiService,
              private themeService: ThemeService,
              private gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.gaService.sendEvent(GA4Events.selectDestination, 'true');
    this.setDestinationSubscription();
    this.themeSubscription = this.themeService.isDarkTheme$.subscribe(darkMode => this.darkMode = darkMode);
  }

  ngOnDestroy(): void {
    this.destinationSubscription.unsubscribe();
    this.themeSubscription.unsubscribe();
  }

  private setDestinationSubscription(): void {
    this.destinationSubscription = this.apiService.destination$.subscribe(destinations => {
      if (typeof destinations === 'string') {
        this.displayText = destinations;
        this.destinations = [];
      } else {
        this.displayText = undefined;
        this.destinations = destinations;
      }
    });
  }
}
