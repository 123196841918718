import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { StepperService } from '../../services/stepper.service';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { VehicleType } from '../../models/VehicleType';
import { ApiService } from '../../services/api.service';
import { getCurrentState, pulsatingButton } from 'src/app/animations/animations';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { GA4Events } from '../../models/constants/GA4Events';
import { EstimationResponse } from '../../models/EstimationResponse';

@Component({
  selector: 'app-vehicle-type',
  animations: [pulsatingButton],
  templateUrl: './vehicle-type.component.html',
  styleUrls: ['./vehicle-type.component.scss']
})
export class VehicleTypeComponent implements OnInit, OnDestroy {
  private bookingDetailsSubscription!: Subscription;
  private configurationSubscription!: Subscription;
  @Input() next: () => void = () => {
  };
  @Input() screenHeight: number = 0;

  private bookingDetails: any = {};
  public vehicleTypes: VehicleType[] = [];
  public selectedVehicleType?: VehicleType;
  public pickupTime: any | null;
  public futureBookingEnabled: boolean = true;
  public priceEstimationRequired: boolean = false;
  private sheetClass: string = 'custom-sheet-corner-light';

  isDarkMode: any;
  fabColor: any;

  public animationStateCounter: number = 0;

  constructor(private stepperService: StepperService,
              private apiService: ApiService,
              public themeService: ThemeService,
              private _bottomSheet: MatBottomSheet,
              private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.setBookingDetailsSubscription();
    this.setThemeSettingSubscription();
    this.setConfigurationSubscription();
    this.gaService.sendEvent(GA4Events.vehicleType, 'true');

  }

  public nextState(): void {
    this.animationStateCounter += 1;
  }

  public getCurrentState(): string {
    return getCurrentState(this.animationStateCounter);
  }

  private setConfigurationSubscription(): void {
    this.configurationSubscription = this.apiService.butlerConfiguration$.subscribe(configuration => {
      if (configuration) {
        this.futureBookingEnabled = configuration.futureBookingDetails.enabled;
        this.priceEstimationRequired = configuration.priceEstimationRequired;
        this.vehicleTypes = configuration.vehicleTypes;
        this.parseVehicleTypes();
      }
    });
  }

  private setSelectedVehicleType(): void {
    if (this.bookingDetails.carType) {
      this.selectedVehicleType = this.vehicleTypes.find(vehicleType => vehicleType.valueKey === this.bookingDetails.carType.valueKey);
    } else {
      this.selectedVehicleType = this.availableVehicleTypes().length > 0 ? this.availableVehicleTypes()[0] : undefined;
    }
  }

  private parseVehicleTypes(): void {
    this.vehicleTypes.forEach(vehicleType => {
      vehicleType.calculatingPrice = true;
      vehicleType.disabled = this.priceEstimationRequired;
      const bookingDetailsCopy = { ...this.bookingDetails };
      bookingDetailsCopy.carType = vehicleType;
      bookingDetailsCopy.reservation = this.pickupTime;

      this.apiService.calculatePriceEstimation(bookingDetailsCopy)
        .subscribe({
          next: (estimationResponse: EstimationResponse) => {
            if (estimationResponse.errorCode) {
              vehicleType.calculatingPrice = false;
              vehicleType.estimatedFullPrice = '';
            } else {
              vehicleType.calculatingPrice = false;
              vehicleType.estimatedFullPrice = estimationResponse.currency + ' ' + this.calculateTotalFee(estimationResponse).toFixed(2);
              vehicleType.basePrice = Number(estimationResponse.estimatedFare);
              vehicleType.fixedServiceFeeAmount = estimationResponse.fixedServiceFeeAmount;
              vehicleType.variableServiceFeeAmount = estimationResponse.variableServiceFeeAmount;
              vehicleType.variableServiceFeePercentage = estimationResponse.variableServiceFeePercentage;
              vehicleType.reservationAmount = estimationResponse.reservationAmount;
              vehicleType.currency = estimationResponse.currency;
              vehicleType.disabled = false;
            }
          },
          error: () => {
            vehicleType.calculatingPrice = false;
            vehicleType.estimatedFullPrice = '';
            this.setSelectedVehicleType();
          },
          complete: () => {
            this.setSelectedVehicleType();
          }
        });
    });
  }

  private setThemeSettingSubscription(): void {
    this.themeService.isDarkTheme$.subscribe(isDarkMode => {
      this.isDarkMode = isDarkMode;
      if (isDarkMode) {
        this.fabColor = 'black';
        this.sheetClass = 'custom-sheet-corner-dark';
      } else {
        this.fabColor = 'white';
        this.sheetClass = 'custom-sheet-corner-light';
      }
    });
  }

  private setBookingDetailsSubscription(): void {
    this.bookingDetailsSubscription = this.stepperService.bookingDetails$.subscribe(bookingDetails => {
      this.bookingDetails = bookingDetails;
      this.pickupTime = bookingDetails.reservation;
    });
  }

  ngOnDestroy(): void {
    this.bookingDetailsSubscription.unsubscribe();
    this.configurationSubscription.unsubscribe();
  }

  confirmVehicleType(): void {
    if (this.selectedVehicleType != undefined) {
      this.bookingDetails.carType = this.selectedVehicleType;
      this.bookingDetails.reservation = this.pickupTime;
      this.stepperService.updateBookingDetails(this.bookingDetails);
      this.next();
    }
  }

  public openBottomSheet(): void {
    this.gaService.sendEvent(GA4Events.futureBookingOpened, 'true');

    this._bottomSheet.open(BottomSheetPlanBookingComponent, {
      panelClass: this.sheetClass,
      data: {
        pickupTime: this.pickupTime
      }

    }).afterDismissed().subscribe(result => {
      if (result) {
        this.pickupTime = { value: result, valueKey: result };
      } else {
        this.pickupTime = null;
      }
      // refresh the estimations when the time is changed
      this.parseVehicleTypes();
    });
  }

  public selectVehicleType(vehicleType: VehicleType): void {
    if(!vehicleType.disabled) {
      this.selectedVehicleType = vehicleType;
    }
  }

  private calculateTotalFee(estimationResponse: EstimationResponse): number {
    let totalFare = Number(estimationResponse.estimatedFare);
    if(estimationResponse.fixedServiceFeeAmount) {
      totalFare += estimationResponse.fixedServiceFeeAmount;
    }
    if(estimationResponse.variableServiceFeeAmount) {
      totalFare += estimationResponse.variableServiceFeeAmount;
    }

    return totalFare;
  }

  private availableVehicleTypes(): VehicleType[] {
    return this.vehicleTypes.filter(v => !v.disabled);
  }

  public calculatingPriceEstimation(): boolean {
    return this.priceEstimationRequired && this.vehicleTypes.filter(v => v.calculatingPrice).length !== 0;
  }
}

@Component({
  selector: 'app-plan-booking-sheet',
  templateUrl: 'plan-booking-sheet.html',
})
export class BottomSheetPlanBookingComponent {

  public pickupTime = '';
  constructor(public _bottomSheetRef: MatBottomSheetRef<BottomSheetPlanBookingComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: any
  ) {
    this.pickupTime = this.data.pickupTime;
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();

  }
}
