import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ThemeService } from '../../../services/theme.service';
import { StepperService } from '../../../services/stepper.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { GA4Events } from '../../../models/constants/GA4Events';

@Component({
  selector: 'app-no-confirmation',
  templateUrl: './no-confirmation.component.html',
  styleUrls: ['./no-confirmation.component.scss']
})
export class NoConfirmationComponent implements OnInit, OnDestroy {
  private stepperSubscription!: Subscription;
  @Input() next: () => void = () => { };
  @Input() screenHeight: number = 0;

  @ViewChildren('codeInput') codeInputElements?: QueryList<ElementRef>;

  public bookingDetails: any = {};
  public tripCreationFailed = false;

  constructor(private stepperService: StepperService,
    private router: Router,
    private apiService: ApiService,
    public themeService: ThemeService,
    private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.setStepperSubscription();
    this.createTrip();
    this.gaService.sendEvent(GA4Events.confirmBooking, 'true');
  }

  ngOnDestroy(): void {
    this.stepperSubscription.unsubscribe();
  }

  private setStepperSubscription(): void {
    this.stepperSubscription = this.stepperService.bookingDetails$.subscribe(bookingDetails => {
      this.bookingDetails = bookingDetails;
    });
  }

  private createTrip(): void {
    this.apiService.createTrip(this.bookingDetails)
      .then(response => {
        if (response.redirectionUrl) {
          this.gaService.sendEvent(GA4Events.redirectMBT, 'true');
          window.location.href = response.redirectionUrl;
        }
      })
      .catch(() => {
        this.tripCreationFailed = true;
    });
  }

  bookAgain(): void {
    this.router.navigate([''], { queryParams: { retry: true }, queryParamsHandling: 'merge', replaceUrl: true });
    location.reload();
  }
}
