import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {ApiService} from './api.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private authenticated = false;
  private loadingSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSource.asObservable();

  constructor(private router: Router,
              private apiService: ApiService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {
    if (!this.authenticated) {
      const params = route.queryParamMap;

      if (!params.get('k') || !params.get('i')) {
        this.router.navigate(['404']).then();
        return false;
      }

      return new Promise<boolean>(resolve => {
        this.loadingSource.next(true);
        this.apiService.getConfiguration(Number(params.get('i')), params.get('k')!)
          .then(authenticated => {
            this.loadingSource.next(false);
            this.authenticated = authenticated;
            if (authenticated) {
              resolve(authenticated);
            } else {
              this.router.navigate(['404']).then();
            }
          });
      });
    } else {
      return true;
    }
  }
}
