<div [ngClass]="[darkMode ? 'destination-background-dark' : 'destination-background-light', 'destination-background']" [ngStyle]="{'height': screenHeight + 'px'}" style="overflow-y: auto;">

  <div *ngFor="let destination of destinations" class="destination-result" (click)="confirmDestination(destination)">
    <mat-icon>place</mat-icon>
    <div class="location">

      <div class="location-details">
        <h6 class="location-name">{{destination.title}}</h6>
        <p class="location-address">{{destination.subTitle}}</p>
      </div>

      <div class="location-distance">
        <p id="distance" >{{destination.indicator}}</p>
        <p>{{destination.indicatorUnit | translate}}</p>
      </div>

    </div>
  </div>
  <span class="no-results" *ngIf="displayText">{{displayText | translate}}</span>
</div>
