<mat-card class="where-to-card mat-elevation-z3" >
  <div #whereTo class="where-to-header">
    <h6>{{'WHERE_TO' | translate}}</h6>
  </div>
    <div class="where-to-body">
      <mat-form-field appearance="outline" class="where-to-input">
        <mat-label>{{'SEARCH_FOR_DESTINATION' | translate}}</mat-label>
        <input matInput placeholder="{{'SEARCH_FOR_DESTINATION' | translate}}" #destinationSearchInput autocomplete="off">
        <button *ngIf="searchValue" matSuffix mat-icon-button (click)="clearInput()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
</mat-card>
