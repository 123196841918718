<mat-toolbar [ngClass]="isDarkMode || darkParam ? 'toolbar-dark' : 'toolbar-light'">
  <ng-container *ngIf="!searchOpen">
    <button *ngIf="displayHamburger && !externalOpen" mat-icon-button class="hamburger-icon" (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
    <button *ngIf="displayBackArrow()" mat-icon-button class="hamburger-icon" (click)="previous()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div *ngIf="!selectCountryOpen">
      {{name}}
    </div>
    <div *ngIf="selectCountryOpen">
      {{ 'SELECT_COUNTRY' | translate}}
    </div>
    <span *ngIf="isSmallScreen || !selectCountryOpen" class="spacer"></span>
    <div *ngIf="!isSmallScreen && selectCountryOpen" class="spacer-block"></div>
    <app-language-picker *ngIf="!selectCountryOpen"></app-language-picker>
    <mat-icon *ngIf="selectCountryOpen" style="flex: none" (click)="openSearch()">search</mat-icon>
  </ng-container>

  <ng-template [ngIf]="searchOpen">
    <app-search [ngClass]="isSmallScreen ? 'small-search' : 'big-search'"></app-search>
    <span *ngIf="isSmallScreen" class="spacer"></span>
    <mat-icon style="flex: none" (click)="closeSearch()">close</mat-icon>
  </ng-template>
</mat-toolbar>
