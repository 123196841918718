export interface ConfigurationStep {
  description: string;
  type: ConfigurationStepType;
}

export enum ConfigurationStepType {
  DESTINATION = 'DESTINATION',
  VEHICLE_TYPE = 'VEHICLE_TYPE',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  BOOKING_OVERVIEW = 'BOOKING_OVERVIEW',
  NO_CONFIRMATION = 'NO_CONFIRMATION',
  SMS_CONFIRMATION = 'SMS_CONFIRMATION',
  PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION'
}
