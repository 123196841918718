import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';
import { Theme } from '../../models/Theme';
import { FormControl, Validators } from '@angular/forms';
import { whitespaceValidator } from '../../components/personal-information/personal-information.component';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice-email',
  templateUrl: './invoice-email.component.html',
  styleUrls: ['./invoice-email.component.scss']
})
export class InvoiceEmailComponent implements OnInit, OnDestroy {
  themeServiceSubscription: Subscription | undefined;

  public proColor: string = '#ffcc00';
  public isDarkMode: boolean = false;
  public logoUrl?: string = undefined;

  public emailSubmitted: boolean = false;

  public invoiceMailForm = new FormControl('', [Validators.required, whitespaceValidator]);
  private tripId: string | null = null;
  private hash: string | null = null;
  public showErrorMessage: boolean = false;


  constructor(private route: ActivatedRoute,
              private themeService: ThemeService,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.setThemeSubscription();
    this.tripId = this.route.snapshot.queryParamMap.get('t');
    this.hash = this.route.snapshot.queryParamMap.get('k');
  }

  ngOnDestroy(): void {
    this.themeServiceSubscription?.unsubscribe();
  }

  private setThemeSubscription(): void {
    this.themeServiceSubscription = this.themeService.themeDetails$.subscribe(themeDetails => {
      this.proColor = themeDetails.primaryColor;
      this.isDarkMode = themeDetails.theme === Theme.DARK;
      this.logoUrl = themeDetails.logoUrl;
    });
  }

  public isValid(): boolean {
    return this.invoiceMailForm.valid;
  }
  public submit(): void {
    this.showErrorMessage = false;
    this.apiService.sendInvoiceToEmail(this.tripId, this.invoiceMailForm.value, this.hash).then(() => {
      this.emailSubmitted = true;
    }).catch(err => {
      this.showErrorMessage = true;
      console.error(err);
    });
  }

  public handleMissingImage() {
    this.logoUrl = undefined;
  }
}
