<div>
  <mat-checkbox (change)="check($event)" [(ngModel)]="checked" class="checkbox">
    {{ 'AGREE' | translate }}
    <div class="link" (click)="goToTC()">{{ 'TERMS_OF_USE' | translate }}</div>
    {{ 'AND' | translate }}
    <div class="link" (click)="goToPP()">{{ 'PP' | translate}}</div>
    {{ 'AND_ALSO' | translate }} {{ partnerName }}
    <div (click)="goToDispatcherTerms()" class="link inline">
      {{ 'TERMS' | translate }}
    </div>
  </mat-checkbox>
</div>
