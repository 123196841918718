import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxHotjarService } from 'ngx-hotjar';

@Injectable({
  providedIn: 'root'
})
export class CookieSettingsService {

  cookiesAccepted: string = 'functional';

  constructor(private cookieService: CookieService,
              private hotJarService: NgxHotjarService) {
  }

  public getCookieTye(): string {
    this.cookiesAccepted = this.cookieService.get('acceptedCookies');

    if (!this.cookiesAccepted) {
      this.cookieService.set('acceptedCookies', 'functional');
      this.cookiesAccepted = 'functional';
    }

    if (this.cookiesAccepted === 'all') {
      this.hotJarService.hj('');
    }
    return this.cookiesAccepted;
  }

  public cookieDoesNotExists(): boolean {
    const cookie = this.cookieService.get('acceptedCookies');
    return (cookie === null || cookie === undefined || cookie === '');
  }

  public setCookiesType(value: any): void {
    this.cookiesAccepted = value;
    this.cookieService.set('acceptedCookies', value);

    if (value === 'all') {
      this.hotJarService.hj('');
    }
  }
}
