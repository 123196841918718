import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {ThemeService} from '../../services/theme.service';
import {Subscription} from 'rxjs';
import Languages from '../../models/constants/Languages';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit, OnDestroy {

  @HostBinding('style.--svg-chevron-color')
  svgChevronColor = 'white';

  selected: string = 'en';
  darkMode: boolean | undefined;
  languages: any = Languages();

  private themeServiceSubscription!: Subscription;
  private butlerConfigurationSubscription!: Subscription;

  constructor(private translateService: TranslateService,
              private cookieService: CookieService,
              private apiService: ApiService,
              private themeService: ThemeService,
              private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.setThemeSettings();
    this.setLanguageSettings();
  }

  ngOnDestroy(): void {
    this.themeServiceSubscription.unsubscribe();
    this.butlerConfigurationSubscription.unsubscribe();
  }

  public changeLanguage(selectedValue: string): void {
    this.cookieService.set('selectedLanguage', selectedValue);
    this.translateService.use(selectedValue);
    this.apiService.selectedLanguageSource.next(selectedValue);
  }

  private setLanguageSettings(): void {
    this.translateService.setDefaultLang('en');
    this.butlerConfigurationSubscription = this.apiService.butlerConfiguration$.subscribe(config => {
      if (config) {
        if(!this.cookieService.check('selectedLanguage')) {
          this.selected = this.parseVenueLanguage(config!.lang);
        } else {
          this.selected = this.cookieService.get('selectedLanguage');
        }
        this.changeLanguage(this.selected);
      }
    });
  }

  private setThemeSettings(): void {
    if (window.location.href.indexOf('checkout') > -1) {
      this.darkMode = this.route.snapshot.queryParams['d']! === 'true';
    } else {
      this.themeServiceSubscription = this.themeService.isDarkTheme$
        .subscribe(darkMode => {
          this.darkMode = darkMode;
        });
    }
    this.svgChevronColor = this.darkMode ? 'white' : 'black';
  }

  private parseVenueLanguage(venueLanguage: string) {
    switch(venueLanguage) {
      case 'de':
      case 'de-ch':
      case 'de-res':
      case 'de-wag':
        return 'de';
      case 'fr':
        return 'fr';
      case 'bg':
        return 'bg';
      case 'nl':
        return 'nl';
      case 'en-cab':
      case 'en-car':
      case 'en-usa':
      default:
        return 'en';
    }
  }
}
