export enum TripStates {
  PENDING_PAYMENT = 'PAYMENT_AUTHORIZATION_PENDING',
  FAILED = 'PAYMENT_AUTHORIZATION_FAILED',
  EXPIRED = 'EXPIRED',
  SUCCESSFUL = 'PAYMENT_AUTHORIZATION_SUCCESSFUL',
  PENDING = 'PENDING',
  REQUESTED = 'REQUESTED',
  DRIVING_TO_PICKUP = 'DRIVING_TO_PICKUP',
  ARRIVED_AT_PICKUP = 'ARRIVED_AT_PICKUP',
  CANCELLED = 'CANCELLED',
  ABORTED = 'CANCELLED_BY_DRIVER',
  CAR_NOT_AVAILABLE = 'CAR_NOT_AVAILABLE'
}

export function FinalTripStates(): TripStates[] {
  return [
    TripStates.PENDING,
    TripStates.REQUESTED,
    TripStates.DRIVING_TO_PICKUP,
    TripStates.ARRIVED_AT_PICKUP,
    TripStates.CANCELLED,
    TripStates.ABORTED,
    TripStates.CAR_NOT_AVAILABLE];
}
