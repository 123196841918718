function languages(): any {
 return [
    {value: 'en', viewValue: 'EN'},
    {value: 'de', viewValue: 'DE'},
    {value: 'es', viewValue: 'ES'},
    // {value: 'it', viewValue: 'IT'},
    {value: 'fr', viewValue: 'FR'},
    {value: 'nl', viewValue: 'NL'},
    {value: 'bg', viewValue: 'BU'}
  ];
}

export default languages;
