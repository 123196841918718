import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';
import { TitleHelperService } from '../../services/title-helper.service';
import { ApiService } from '../../services/api.service';
import { Theme } from '../../models/Theme';

@Component({
  selector: 'app-layout-structure',
  templateUrl: './layout-structure.component.html',
  styleUrls: ['./layout-structure.component.scss']
})
export class LayoutStructureComponent implements OnDestroy, OnInit {
  private routerSubscription!: Subscription;

  @ViewChild('sidenav') sidenav: any;
  public portrait: boolean = true;
  public isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  public darkMode!: boolean;
  public title = '';
  private apiSub!: Subscription;

  constructor(private router: Router,
              private elementRef: ElementRef,
              private themeService: ThemeService,
              private titleHelper: TitleHelperService,
              private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.apiSub = this.apiService.butlerConfiguration$.subscribe(conf => {
      if (conf) {
        this.themeService.updateTheme(conf?.themeDetails!);
        this.darkMode = conf.themeDetails.theme === Theme.DARK;
      }
    });

    this.checkAngleAndMobile();
    this.setRouterSubscription();
    this.title = this.titleHelper.getTitleText();
  }

  private setRouterSubscription(): void {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.sidenav) {
          this.sidenav.close();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.apiSub.unsubscribe();
  }

  @HostListener('window:resize', [])
  private onResize(): void {
    this.checkAngleAndMobile();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(): void {
    this.checkAngleAndMobile();
  }

  private checkAngleAndMobile(): void {
    if (this.isMobileDevice) {
      this.portrait = window.matchMedia('(orientation: portrait)').matches;
      if (this.portrait && this.darkMode) {
        this.elementRef.nativeElement.ownerDocument
          .body.style.backgroundColor = '#242624';
      }
    }
  }
}
