import {ModuleWithProviders, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StepperComponent} from './components/stepper/stepper.component';
import {LayoutComponent} from './components/layout/layout.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutStructureComponent} from './components/layout-structure/layout-structure.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {TopBarComponent} from './components/top-bar/top-bar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {SidenavListComponent} from './components/sidenav-list/sidenav-list.component';
import {MatListModule} from '@angular/material/list';
import {DestinationComponent} from './components/destination/destination.component';
import {BottomSheetPlanBookingComponent, VehicleTypeComponent} from './components/vehicle-type/vehicle-type.component';
import {PersonalInformationComponent} from './components/personal-information/personal-information.component';
import {SmsConfirmationComponent} from './components/confirmation/sms/sms-confirmation.component';
import {WhereToCardComponent} from './components/dialogs/where-to-card/where-to-card.component';
import {HomeComponent} from './pages/home/home.component';
import {ErrorDialogComponent} from './components/dialogs/error-dialog/error-dialog.component';
import {DestinationResultsComponent} from './components/destination-results/destination-results.component';
import {LanguagePickerComponent} from './components/language-picker/language-picker.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {MapComponent} from './components/map/map.component';
import {PlannerComponent} from './components/planner/planner.component';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet} from '@angular/material/bottom-sheet';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateTranslatePipe} from './pipes/date-translate.pipe';
import {SelectCountryComponent} from './components/select-country/select-country.component';
import {SearchComponent} from './components/search/search.component';
import {AlphabeticalScrollBarModule} from 'alphabetical-scroll-bar';
import {CookiePageComponent} from './pages/cookie-page/cookie-page.component';
import {NgxHotjarModule, NgxHotjarRouterModule} from 'ngx-hotjar';
import {
  BookingOverviewComponent,
  PriceBreakdownDialogComponent
} from './components/booking-overview/booking-overview.component';
import {GoogleAnalyticsService} from './services/google-analytics.service';
import {AdyenPaymentComponent} from './components/confirmation/adyen-payment/adyen-payment.component';
import {CheckoutComponent} from './pages/checkout/checkout.component';
import {WaitingPaymentComponent} from './components/waiting-payment/waiting-payment.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {NoConfirmationComponent} from './components/confirmation/no-confirmation/no-confirmation.component';
import {CookieToastComponent} from './components/cookie-toast/cookie-toast.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {LegalConfirmComponent} from './components/legal-confirm/legal-confirm.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {InvoiceEmailComponent} from './pages/invoice-email/invoice-email.component';
import {MatRadioModule} from '@angular/material/radio';
import {LegalDocumentComponent} from './components/legal-document/legal-document.component';
import {LegalDocumentPageComponent} from './pages/legal-document-page/legal-document-page.component';
import {CacheInterceptor} from './interceptors/cache.interceptor';
import { CookieNoticeViewerComponent } from './components/cookie-notice-viewer/cookie-notice-viewer.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

function canLoadHJ(): ModuleWithProviders<NgxHotjarModule> {
  if (document.cookie.split(';').includes(' acceptedCookies=all')) {
    return NgxHotjarModule.forRoot(window.location.hostname.includes('venuebutler') ? '3247943' : '3247942');
  }
  return NgxHotjarModule.forRoot('');
}

@NgModule({
  declarations: [
    AppComponent,
    StepperComponent,
    LayoutComponent,
    LayoutStructureComponent,
    TopBarComponent,
    SidenavListComponent,
    WhereToCardComponent,
    DestinationComponent,
    VehicleTypeComponent,
    PersonalInformationComponent,
    NoConfirmationComponent,
    SmsConfirmationComponent,
    HomeComponent,
    ErrorDialogComponent,
    LanguagePickerComponent,
    DestinationResultsComponent,
    ConfirmationDialogComponent,
    MapComponent,
    PlannerComponent,
    BottomSheetPlanBookingComponent,
    NotFoundComponent,
    DateTranslatePipe,
    SelectCountryComponent,
    SearchComponent,
    BookingOverviewComponent,
    PriceBreakdownDialogComponent,
    AdyenPaymentComponent,
    CheckoutComponent,
    WaitingPaymentComponent,
    LegalConfirmComponent,
    LegalConfirmComponent,
    CookiePageComponent,
    CookieToastComponent,
    InvoiceEmailComponent,
    LegalDocumentComponent,
    CookieNoticeViewerComponent,
    LegalDocumentPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatCardModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDialogModule,
    TranslateModule,
    GoogleMapsModule,
    MatDatepickerModule,
    AlphabeticalScrollBarModule,
    NgxHotjarRouterModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatCheckboxModule,
    PdfViewerModule,
    MatRadioModule,
    canLoadHJ()
  ],
  providers: [{provide: MatBottomSheet},
    {provide: MAT_BOTTOM_SHEET_DATA, useValue: {}},
    {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
