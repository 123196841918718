import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Country} from '../models/Country';
import Countries from '../models/constants/Countries';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SelectCountryService {

  private selectCountryOpenSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectCountryOpen$: Observable<boolean> = this.selectCountryOpenSource.asObservable();

  private selectedCountrySource: BehaviorSubject<Country> = new BehaviorSubject<Country>(Countries()[0]);
  public selectedCountry$: Observable<Country> = this.selectedCountrySource.asObservable();

  private listOfCountriesSource: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>(Countries());
  public listOfCountries$: Observable<Country[]> = this.listOfCountriesSource.asObservable();

  private searchOpenSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public searchOpen$: Observable<boolean> = this.searchOpenSource.asObservable();

  constructor(private translate: TranslateService) { }

  public setSelectCountryOpen(isOpen: boolean): void {
    this.selectCountryOpenSource.next(isOpen);
    this.searchOpenSource.next(isOpen);
  }

  public setSearchOpen(isOpen: boolean): void {
    this.searchOpenSource.next(isOpen);
  }

  public setSelectCountry(country: Country): void {
    this.selectedCountrySource.next(country);
  }

  public search(term: string): void {
    if (term.length <= 2) {
      this.resetSearch();
    } else {
      this.listOfCountriesSource.next(this.filterCountries(term));
    }
  }

  private filterCountries(searchTerm: string): any {
    return Countries().filter(country => this.getCountryTranslation(country.countryCode).toLowerCase().includes(searchTerm.toLowerCase()) || country.nativeName?.toLocaleLowerCase().includes(searchTerm.toLowerCase()));
  }

  public resetSearch(): void {
    this.listOfCountriesSource.next(Countries());
  }

  public getCountryTranslation(countryCode: string): string {
    const translationKey = 'COUNTRY_CODE_' + countryCode.toUpperCase();
    return this.translate.instant(translationKey);
  }
}
