import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LegalService } from 'src/app/services/legal.service';
import { ActivatedRoute } from '@angular/router';
import { LegalDocumentType } from 'src/app/models/LegalDocumentType';

@Component({
  selector: 'app-legal-document-page',
  templateUrl: './legal-document-page.component.html',
  styleUrls: ['./legal-document-page.component.scss']
})
export class LegalDocumentPageComponent implements OnInit, OnDestroy {
  private paramSub!: Subscription;
  public useIframe: boolean = false;

  constructor(private route: ActivatedRoute,
              private legalService: LegalService) {
  }

  ngOnInit() {
    this.paramSub = this.route.paramMap.subscribe(paramMap => {
      const documentType = <LegalDocumentType> paramMap.get('type');
      this.legalService.setLegalDocumentType(documentType);
      this.useIframe = documentType !== LegalDocumentType.COOKIE_NOTICE;
    });
  }

  ngOnDestroy(): void {
    this.paramSub.unsubscribe();
  }
}
