import { Component, OnDestroy } from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {LegalService} from '../../services/legal.service';
import { Subscription } from 'rxjs';
import { StepperService } from 'src/app/services/stepper.service';
import { ApiService } from '../../services/api.service';
import { LegalDocumentType } from 'src/app/models/LegalDocumentType';

@Component({
  selector: 'app-legal-confirm',
  templateUrl: './legal-confirm.component.html',
  styleUrls: ['./legal-confirm.component.scss']
})
export class LegalConfirmComponent implements OnDestroy {

  checked: boolean = false;
  private bookingDetails: any;
  private confirmedSubscription: Subscription;
  private configSubscription!: Subscription;
  partnerName: string = 'transportation provider';
  private bookingDetailsSubscription!: Subscription;

  constructor(private legalService: LegalService,
              private apiService: ApiService, private stepperService: StepperService) {
    this.confirmedSubscription = this.legalService.isConfirmed$.subscribe(isChecked => this.checked = isChecked);
    this.bookingDetailsSubscription = this.stepperService.bookingDetails$.subscribe(bookingDetails => this.bookingDetails = bookingDetails);
    this.configSubscription = this.apiService.butlerConfiguration$.subscribe(config => {
      if (config?.companyDetails.displayName) {
        this.partnerName = config?.companyDetails.displayName;
      }
    });
  }

  public goToTC(): void {
    this.legalService.setLegalDocumentType(LegalDocumentType.TERMS_OF_USE);
    this.legalService.setLegalOpen(true);
    this.keepCheckedInSameState();
  }

  public goToPP(): void {
    this.legalService.setLegalDocumentType(LegalDocumentType.PRIVACY_POLICY);
    this.legalService.setLegalOpen(true);
    this.keepCheckedInSameState();
  }

  public goToDispatcherTerms() {
    this.legalService.setLegalDocumentType(LegalDocumentType.DISPATCHER_TERMS);
    this.legalService.setLegalOpen(true);
    this.keepCheckedInSameState();
  }

  public check($event: MatCheckboxChange): void {
    this.legalService.setConfirmed($event.checked);
    this.bookingDetails.confirmedPPAndTC = $event.checked;
    this.stepperService.updateBookingDetails(this.bookingDetails);
  }

  ngOnDestroy() {
    this.confirmedSubscription.unsubscribe();
    this.bookingDetailsSubscription.unsubscribe();
  }

  private keepCheckedInSameState(): void {
    this.checked = !this.checked;
  }
}
