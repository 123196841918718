<mat-card class="confirmation" *ngIf="!showExpired && !tripCreationFailed">
  <div class="confirmation-title">
    <h6>{{'PLEASE_CONFIRM_BOOKING' | translate}}</h6>
  </div>

  <div class="confirmation-content">

    <img src="{{themeService.getVehicleIcon(bookingDetails.carType.icon)}}" class="confirmation-icon">

    <h3>{{'CONFIRM_AWAITING' | translate}}</h3>
    <ng-container *ngIf="!verifying">
      <p class="confirm-code">{{'CONFIRM_UNIQUE_CODE' | translate: {phoneNumber: bookingDetails.telephone.value } }}</p>
      <div class="code-form">
        <mat-form-field appearance="outline" class="code-input" *ngFor="let row of inputRows">
          <input role="presentation" matInput type="number" #codeInput  pattern="[0-9]*" min="0" max="9"
          (input)="splitNumber($event, row)" (focus)="onFocus(row)" (keyup)="onKeyUp($event, row)"
          [(ngModel)]="codeArray[row]" (ngModelChange)="verifyCode()" autocomplete="off">
        </mat-form-field>
      </div>
      <p *ngIf="codeWrong" class="confirmation-failed">{{'CONFIRM_FAILED' | translate}}</p>
      <p class="confirmation-expire">{{'CONFIRM_EXPIRE' | translate}}</p>
      <h4 class="confirmation-countdown-big-screen" [ngStyle]="{'margin-bottom': codeWrong ?  this.screenHeight - 670 +'px' : this.screenHeight - 635 +'px'}">{{'COUNTDOWN_TEXT' | translate: {
        minutes: countdownMinutes,
        seconds: countdownSeconds
      } }}</h4>
    </ng-container>
    <ng-container *ngIf="verifying">
      <mat-spinner [ngStyle]="{'margin-bottom': this.screenHeight - 526 +'px'}" class="spinner" [diameter]="30"></mat-spinner>
    </ng-container>
  </div>
</mat-card>
<mat-card class="confirmation" *ngIf="showExpired">
  <div class="confirmation-title">
    <h6>{{'YOUR_BOOKING_HAS_EXPIRED' | translate}}</h6>
  </div>
  <div class="confirmation-content">
    <img src="{{themeService.getVehicleIcon(bookingDetails.carType.icon)}}" class="confirmation-icon">
    <h3>{{'BOOKING_EXPIRED' | translate}}</h3>
    <div>
      {{'SORRY_BOOKING_CODE_EXPIRED' | translate}}
    </div>
    <button style="margin-top: 12px; text-transform: uppercase"
            mat-button
            (click)="bookAgain()"
            class="normal-button">
      {{'BOOK_AGAIN' | translate}}
    </button>
  </div>
</mat-card>
<mat-card class="confirmation" *ngIf="tripCreationFailed">
  <div class="confirmation-content">
    <img src="{{themeService.getVehicleIcon(bookingDetails.carType.icon)}}" alt="" class="confirmation-icon">
    <h3>{{'WENT_WRONG' | translate}}</h3>
    <div>
      {{'PLEASE_TRY_AGAIN' | translate}}
    </div>
    <button style="margin-top: 12px; text-transform: uppercase"
            mat-button
            (click)="bookAgain()"
            class="normal-button">
      {{'BOOK_AGAIN' | translate}}
    </button>
  </div>
</mat-card>