import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentService } from '../../../services/payment.service';
import { AdyenSessionService } from '../../../services/adyen-session.service';
import { ApiService } from 'src/app/services/api.service';
import { StepperService } from 'src/app/services/stepper.service';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';
import { CreateTripResponse } from '../../../models/CreateTripResponse';
import { AdyenCheckoutConfiguration } from '../../../models/AdyenCheckoutConfiguration';
import { AdyenSessionErrorType } from '../../../models/AdyenSessionErrorType';

@Component({
  selector: 'app-adyen-payment',
  templateUrl: './adyen-payment.component.html',
  styleUrls: ['./adyen-payment.component.scss']
})
export class AdyenPaymentComponent implements OnInit, OnDestroy {
  public bookingDetails: any = {};
  private stepperSubscription!: Subscription;
  private checkoutConfiguration = new AdyenCheckoutConfiguration(this.paymentService);
  public msgError: string;

  constructor(private paymentService: PaymentService,
              private adyenService: AdyenSessionService,
              private apiService: ApiService,
              private stepperService: StepperService,
              public themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.stepperSubscription = this.stepperService.bookingDetails$.subscribe(bookingDetails => this.bookingDetails = bookingDetails);
    this.checkoutConfiguration.dropinState$.subscribe(result => this.msgError = result);
    this.createTrip();
  }

  ngOnDestroy(): void {
    this.stepperSubscription.unsubscribe();
  }

  showMsgError() : boolean {
    return this.msgError !== AdyenSessionErrorType.DROP_IN_WAITING &&
            this.msgError !== AdyenSessionErrorType.DROP_IN_SUCCESS;
  }

  showDropIn() : boolean {
    return this.msgError !== AdyenSessionErrorType.DROP_IN_WAITING;
  }

  showSpinner() : boolean {
    return this.msgError === AdyenSessionErrorType.DROP_IN_WAITING;
  }

  private createTrip(): void {
    this.apiService.createPaidTrip(this.bookingDetails)
      .then(createPaidTripResponse => {
        this.getAdyenSession(createPaidTripResponse);
      })
      .catch(() => {
        this.msgError = AdyenSessionErrorType.DROP_IN_GENERAL_ERROR;
        this.paymentService.remountDropin().then();
      });
  }

  private getAdyenSession(tripResponse: CreateTripResponse): void {
    this.adyenService.getSessionConfiguration(tripResponse)
      .then(sessionResponse => {
          this.paymentService.createDropIn(this.checkoutConfiguration, sessionResponse, tripResponse.tripId);
      });
  }
}
