<mat-card class="booking-details-wrapper mat-elevation-z0" [ngClass]="darkMode ? 'dark-background-deep' : 'light-background-deep'" [ngStyle]="{'height': screenHeight + 'px'}">
  <div class="booking-details">
    <h6>{{ 'YOUR_INFORMATION' | translate }}</h6>
    <div class="your-information">
      <div class="passenger-detail">
        <div>{{ 'YOUR_NAME' | translate }}:</div>
        <div class="bold">{{bookingDetails.firstName.value + ' ' + bookingDetails.lastName.value}}</div>
      </div>
      <div class="passenger-detail">
        <div>{{ 'PHONE_NUMBER' | translate }}:</div>
        <div class="bold"> {{bookingDetails.telephone.value}}</div>
      </div>
      <div *ngIf="bookingDetails.invoiceMail?.value" class="passenger-detail">
        <div>{{ 'EMAIL' | translate }}:</div>
        <div class="bold">{{bookingDetails.invoiceMail.value}}</div>
      </div>
    </div>
    <div class="price">
      <div class="details-title">
        <h6 *ngIf="bookingDetails.carType.estimatedFullPrice">{{'BOOKING_DETAILS_PRICE' | translate}}
        </h6>
        <h6 *ngIf="!bookingDetails.carType.estimatedFullPrice">{{'BOOKING_DETAILS' | translate}}</h6>
        <p *ngIf="bookingDetails.carType.estimatedFullPrice">{{bookingDetails.carType.estimatedFullPrice}}</p>
      </div>
      <div *ngIf="hasServiceFee()">
        <div class="price-details">
          <div>{{'FARE_ESTIMATE' | translate}} <div class="pro-color">*</div></div>
          <p>{{totalBaseFareAmount()}}</p>
        </div>

        <div *ngIf="this.bookingDetails.carType.variableServiceFeeAmount" class="price-details">
          <div>
            <div class="service-fee"> {{ 'SERVICE_FEE' | translate}} </div>
            <div class="icon-div" (click)="openBreakdown()">
              <mat-icon class="small-icon" [ngStyle]="{'color': darkMode ? '#FFFFFF61' : '#00000061'}">info</mat-icon>
            </div>
          </div>
          <p>{{totalServiceFeeAmount()}}</p>
        </div>

        <div *ngIf="!this.bookingDetails.carType.variableServiceFeeAmount" class="price-details">
          <div>
            <div class="service-fee"> {{ 'SERVICE_FEE_NO_ESTIMATE' | translate}} </div>
          </div>
          <p>{{totalServiceFeeAmount()}}</p>
        </div>

        <div *ngIf="bookingDetails.carType.reservationAmount" class="reservation-info">
          <div class="pro-color">*</div>
          {{'SERVICE_FEE_RESERVED_COSTS' | translate: {
          currency: bookingDetails.carType.currency,
          margin: bookingDetails.carType.reservationAmount.toFixed(2)
        } }}
        </div>
      </div>
      <div *ngIf="!bookingDetails.carType.reservationAmount || bookingDetails.carType.reservationAmount.toFixed(2) <= 0" class="reservation-info">
        <div class="pro-color">*</div>
        {{'NO_RESERVED_COSTS' | translate }}
      </div>
    </div>

    <div class="details-info">
      <img *ngIf="bookingDetails.carType" alt="" src="{{themeService.getVehicleIcon(bookingDetails.carType.icon)}}">
      <div class="details-info-text">
        <h6>{{bookingDetails.carType.label}}</h6>
        <p
          *ngIf="bookingDetails.reservation && bookingDetails.reservation.value">{{bookingDetails.reservation.value | dateTranslate}}
          ,
          {{ bookingDetails.reservation.value | date:'HH:mm'}}
        </p>
        <p *ngIf="!bookingDetails.reservation || !bookingDetails.reservation.value">{{'TIME_ASAP' | translate}}</p>
      </div>
    </div>

    <div class="booking-details2">
      <div class="route">
        <div id="vertically">
          <svg xmlns="http://www.w3.org/2000/svg" class="pickup-icon" height="24px" viewBox="0 0 24 24" width="24px"
               fill="#000000">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
          </svg>
          <ng-container *ngIf="bookingDetails.destination">
            <div class="circle-line" [ngStyle]="{'height': lineHeight + 'px'}"></div>
            <svg id="Destination" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path id="Path_237" data-name="Path 237" d="M0,0H24V24H0Z" fill="none"/>
              <g id="ellipse_45" data-name="Ellipse 45" transform="translate(1.75 1.645)" fill="none" stroke="#24c26e"
                 stroke-width="7">
                <circle cx="10" cy="10" r="10" stroke="none"/>
                <circle cx="10" cy="10" r="6.5" fill="none"/>
              </g>
            </svg>
          </ng-container>
        </div>
        <div>
          <div class="pickup" #pickup_container>
            <div class="sub-header">
              {{ venueName }}</div>
            <div>{{ address }}</div>
          </div>
          <div class="destination">
            <ng-container *ngIf="bookingDetails.destination.displayValue">
              <div class="sub-header">
                {{ bookingDetails.destination.displayValue }}</div>
              <div>{{ getDestinationAddress() }}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-legal-confirm *ngIf="complianceEnabled"></app-legal-confirm>
    <div class="footer-buttons" [ngClass]="darkMode ? 'dark' : 'light'">
      <div [@pulsatingButton]="getCurrentState()" (@pulsatingButton.done)="nextState()" class="vehicle-type-buttons">
        <button *ngIf="!payEnabled" mat-button class="floating-button" [disabled]="!hasAgreedToPPAndTC()"
                (click)="confirmOverview()">{{'FINALISE_BOOKING' | translate}}</button>
        <button *ngIf="payEnabled" mat-button class="floating-button" [disabled]="!hasAgreedToPPAndTC()"
                (click)="confirmOverview()">{{'PROCEED_PAYMENT' | translate}}</button>
      </div>
    </div>
  </div>
</mat-card>
