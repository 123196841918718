import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AutoCompleteItem } from '../../models/AutoCompleteItem';
import { StepperService } from '../../services/stepper.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss']
})
export class DestinationComponent implements OnInit, OnDestroy {
  private bookingDetailsSubscription!: Subscription;
  private bookingDetails: any = {};
  private validateDestinationSubscription: Subscription | undefined;
  private themeSubscription!: Subscription;

  @Input() public next: () => void = () => {
  };
  @Input() screenHeight: number = 0;

  public validating = false;
  public darkMode!: boolean;

  constructor(private stepperService: StepperService,
              private apiService: ApiService, private themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.bookingDetailsSubscription = this.stepperService.bookingDetails$.subscribe(bookingDetails => {
      this.bookingDetails = bookingDetails;
    });
    this.themeSubscription = this.themeService.isDarkTheme$.subscribe(darkMode => this.darkMode = darkMode);

  }

  ngOnDestroy(): void {
    this.bookingDetailsSubscription.unsubscribe();
    if (this.validateDestinationSubscription) {
      this.validateDestinationSubscription.unsubscribe();
    }
    this.themeSubscription.unsubscribe();
  }

  confirmDestination = (destination: AutoCompleteItem): void => {
    // Wait for previous request to finish
    if (this.validating) {
      return;
    }

    this.validating = true;
    if (this.validateDestinationSubscription) {
      this.validateDestinationSubscription.unsubscribe();
    }

    this.apiService.validateDestination(destination)
      .subscribe({
        next: value => {
          this.bookingDetails.destination = value;
          this.stepperService.updateBookingDetails(this.bookingDetails);
          this.bookingDetails.confirmedPPAndTC = false;
          this.next();
          this.validating = false;
        },
        error: err => {
          this.validating = false;
        } // TODO -> handle error!
      });
  };
}
