<div [ngClass]="[this.isDarkMode ? 'invoice-email-dark' : 'invoice-email-light', 'invoice-email']">
  <div *ngIf="logoUrl !== undefined && logoUrl !== ''" class="invoice-logo-container">
    <img src="{{logoUrl}}" (error)="handleMissingImage()" alt="">
  </div>
  <div class="invoice-email-card-container">
    <mat-card class="invoice-email-card">
      <div class="invoice-email-header">
        <h6>{{'MY_RECEIPT' | translate}}</h6>
      </div>
      <form>
        <div class="invoice-card-content" *ngIf="!emailSubmitted">
          <p>
            {{'INVOICE_EMAIL' | translate}}
          </p>
          <mat-form-field appearance="outline">
            <mat-label>{{'MAIL_ADDRESS' | translate}}</mat-label>
            <input [formControl]="invoiceMailForm" matInput type="mail" ngModel [email]="true" required
                   placeholder="johndoe@taxibutler.com" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="invoice-card-content" *ngIf="emailSubmitted">
          <p>
            {{'INVOICE_EMAIL_SUBMITTED' | translate}}
            <b>{{invoiceMailForm.value}}</b>
          </p>
        </div>
        <div class="button-container" *ngIf="!emailSubmitted">
          <div *ngIf="showErrorMessage" class="error"> {{'SOMETHING_WENT_WRONG' | translate}} </div>
          <span class="spacer"></span>
          <button [disabled]="!isValid()" mat-button
                  [ngStyle]="{'background-color': this.proColor , 'text-transform': 'uppercase'}"
                  (click)="submit()">SUBMIT
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
