<mat-card class="confirmation" *ngIf="!tripCreationFailed">
  <div class="confirmation-title">
    <h6>{{'CONFIRMING_BOOKING' | translate}}</h6>
  </div>

  <div class="confirmation-content">

    <img src="{{themeService.getVehicleIcon(bookingDetails.carType.icon)}}" class="confirmation-icon">

    <h3>{{'CONFIRM_AWAITING' | translate}}</h3>
    <ng-container>
      <mat-spinner [ngStyle]="{'margin-bottom': this.screenHeight - 526 +'px'}" class="spinner" [diameter]="30"></mat-spinner>
    </ng-container>
  </div>
</mat-card>
<mat-card class="confirmation" *ngIf="tripCreationFailed">
  <div class="confirmation-content">
    <img src="{{themeService.getVehicleIcon(bookingDetails.carType.icon)}}" alt="" class="confirmation-icon">
    <h3>{{'WENT_WRONG' | translate}}</h3>
    <div>
      {{'PLEASE_TRY_AGAIN' | translate}}
    </div>
    <button style="margin-top: 12px; text-transform: uppercase"
            mat-button
            (click)="bookAgain()"
            class="normal-button">
      {{'BOOK_AGAIN' | translate}}
    </button>
  </div>
</mat-card>