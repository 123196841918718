export const GA4Events = {
  personalInformation: 'step_personal_information',
  bookingOverview: 'step_booking_overview',
  confirmBooking: 'step_confirm_booking',
  redirectMBT: 'redirect_to_mbt',
  selectDestination: 'step_select_destination',
  vehicleType: 'step_vehicle_type',
  futureBookingOpened: 'future_booking_opened'
} as const;

export const GA4PayEvents = {
  personalInformation: 'pay_step_personal_information',
  bookingOverview: 'pay_step_booking_overview',
  payment: 'pay_step_payment',
  redirectMBT: 'pay_redirect_to_mbt',
  selectDestination: 'pay_step_select_destination',
  vehicleType: 'pay_step_vehicle_type',
  futureBookingOpened: 'pay_future_booking_opened'
} as const;
