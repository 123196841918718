import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {ThemeService} from '../../../services/theme.service';
import {Theme} from '../../../models/Theme';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {
  themeServiceSubscription: Subscription | undefined;

  public proColor: string = '#ffcc00';
  isDarkMode: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private themeService: ThemeService) { }

  ngOnInit(): void {
    this.setThemeSubscription();
  }

  private setThemeSubscription(): void {
    this.themeServiceSubscription = this.themeService.themeDetails$.subscribe(themeDetails => {
      this.proColor = themeDetails.primaryColor;
      this.isDarkMode = themeDetails.theme === Theme.DARK;
    });
  }

  ngOnDestroy(): void {
    if (this.themeServiceSubscription) {
      this.themeServiceSubscription.unsubscribe();
    }
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }

}
