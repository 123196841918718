import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {PhoneNumberUtil} from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(regionCode: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validNumber = false;
    try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        control.value, regionCode
      );
      validNumber = phoneNumberUtil.isValidNumberForRegion(phoneNumber, regionCode);
    } catch (e) { }
    return validNumber ? null : { 'wrongNumber': { value: control.value } };
  };
}
