import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { ThemeService } from '../../services/theme.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Theme } from '../../models/Theme';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss']
})
export class PlannerComponent implements OnDestroy, OnInit {
  @Input() bottomSheetRef?: MatBottomSheetRef;
  @Input() pickupTime?: any;

  date: String = '';
  time: String = '';
  public proColor: string = '#ffcc00';
  public proColorOpacity: string = '##FFCC0040';

  public now = new Date();

  minDate: any;
  maxDate: any;

  private themeServiceSubscription?: Subscription;
  private configurationSubscription?: Subscription;

  public invalidDateTime: boolean = false;
  public isDarkMode: boolean = false;

  constructor(private themeService: ThemeService,
              private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.themeServiceSubscription = this.themeService.themeDetails$.subscribe(themeDetails => {
      this.proColor = themeDetails.primaryColor;
      this.proColorOpacity = this.proColor + '40';
      this.isDarkMode = themeDetails.theme === Theme.DARK;
    });

    this.configurationSubscription = this.apiService.butlerConfiguration$.subscribe((conf: any) => {
      const futureDetails: any = conf.futureBookingDetails;
      if (futureDetails.enabled) {
        this.minDate = new Date().getTime() + futureDetails.minMinutes * 60 * 1000;
        this.maxDate = new Date().getTime() + futureDetails.maxMinutes * 60 * 1000;

        this.date = this.pickupTime == undefined ? this.formatDate(this.minDate) : this.formatDate(this.pickupTime.value);
        this.time = this.pickupTime == undefined ? this.formatTime(this.minDate) : this.formatTime(this.pickupTime.value);
      }
    });

    this.bottomSheetRef?.backdropClick().subscribe(() => {
      if (this.pickupTime) {
        this.setTime();
      } else {
        this.asap();
      }
    });
  }

  ngOnDestroy() {
    this.themeServiceSubscription!.unsubscribe();
    this.configurationSubscription!.unsubscribe();
  }

  onChangeDate(): void {
    if (!this.date) {
      this.date = this.formatDate(this.minDate);
    }
  }

  onChangeTime(): void {
    if (!this.time) {
      this.time = this.formatTime(this.minDate);
    }
  }

  asap(): void {
    this.bottomSheetRef!.dismiss(null);
  }

  setTime(): void {
    const dateToSubmit = this.convertToLocalDate(this.date);
    if (dateToSubmit === null) {
      return;
    }
    dateToSubmit.setHours(Number(this.time.slice(0, 2)));
    dateToSubmit.setMinutes(Number(this.time.slice(3, 5)));

    if (dateToSubmit.getTime() > this.minDate && dateToSubmit!.getTime() < this.maxDate) {
      this.invalidDateTime = false;
      this.bottomSheetRef!.dismiss(dateToSubmit);
    } else {
      this.invalidDateTime = true;
    }
  }

  convertToLocalDate(responseDate: any): Date | null {
    try {
      if (responseDate != null) {
        if (typeof (responseDate) === 'string') {
          if (String(responseDate.indexOf('T') >= 0)) {
            responseDate = responseDate.split('T')[0];
          }
          if (String(responseDate.indexOf('+') >= 0)) {
            responseDate = responseDate.split('+')[0];
          }
        }

        responseDate = new Date(responseDate);
        const newDate = new Date(responseDate.getFullYear(), responseDate.getMonth(), responseDate.getDate(), 0, 0, 0);
        const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;

        return new Date(newDate.getTime() - userTimezoneOffset);
      } else {
        return null;
      }
    } catch (error) {
      return responseDate;
    }
  }

  formatTime(date: Date): string {
    return formatDate(date, 'HH:mm', 'en-US');
  }

  formatDate(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }
}
