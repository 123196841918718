import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Theme } from '../../models/Theme';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';
import { CookieSettingsService } from '../../services/cookie-settings.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-cookie-page',
  templateUrl: './cookie-page.component.html',
  styleUrls: ['./cookie-page.component.scss']
})
export class CookiePageComponent implements OnInit, OnDestroy {

  constructor(private themeService: ThemeService,
              private cookieSettingsService: CookieSettingsService,
              private apiService: ApiService) {
  }

  private themeSubscription!: Subscription;
  private apiSub!: Subscription;
  protected readonly location = location;

  ngOnInit(): void {
    this.cookiesAccepted = this.cookieSettingsService.getCookieTye();
    this.apiSub = this.apiService.butlerConfiguration$.subscribe(conf => {
      if (conf) {
        this.themeService.updateTheme(conf?.themeDetails!);
      }
    });

    this.themeSubscription = this.themeService.themeDetails$.subscribe(themeDetails => {
      this.isDark = themeDetails.theme === Theme.DARK.toString();
      this.proColor = themeDetails.primaryColor;
      this.proColorOpacity = themeDetails.primaryColor + '40';
    });
  }

  @HostBinding('style.--pro-color')
  proColor = '#ffcc00';

  @HostBinding('style.--pro-color-opacity')
  proColorOpacity = this.proColor + '40';

  isDark = false;
  cookiesAccepted: string = 'functional';

  setCookies(value: any) {
    this.cookiesAccepted = value;
    this.cookieSettingsService.setCookiesType(value);
  }

  ngOnDestroy(): void {
    this.apiSub.unsubscribe();
    this.themeSubscription.unsubscribe();
    location.reload();
  }

  getNoticeLink(): string {
    return location.href.replace('cookies', 'document/cookie-notice');
  }
}
