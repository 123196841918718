<mat-card class="mat-elevation-z0" [ngClass]="darkMode ? 'dark-background-deep' : 'light-background-deep'"
          [ngStyle]="{'height': screenHeight > 0 ? (screenHeight + 'px') : 'calc(100% - 125px)', 'margin-top': '4px'}">
  <div id="container">
    <div class="passenger-info">
      <div [ngStyle.xs]="{'height': screenHeight}">
        <form>
          <mat-form-field appearance="outline" class="name-form">
            <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
            <input [formControl]="firstNameForm" matInput placeholder="JOHN" autocomplete="off" [name]="'fname'">
          </mat-form-field>

          <mat-form-field appearance="outline" class="name-form">
            <mat-label>{{'LAST_NAME' | translate}}</mat-label>
            <input [formControl]="lastNameForm" matInput placeholder="DOE" autocomplete="off" [name]="'lname'">
          </mat-form-field>

          <div class="country-phone">
            <div [ngClass]="[darkMode ? 'country-div-dark' : 'country-div-light', 'country-div']"
                 [ngStyle]="{'height' : isIOS() ? '59px' : '57.5px'}" tabindex="1" (click)="openSelectCountry()">
            <span [ngClass]="darkMode ? 'title-dark' : 'title-light'">
              <div style="margin: 0 3px 0 3px">
              {{'COUNTRY_SELECT' | translate}}
              </div>
            </span>
              <img class="img-placeholder" alt="" src="/assets/icons/flags/{{countryForm.value.countryCode}}.png">
              <div class="arrow-down"></div>
            </div>

            <mat-form-field appearance="outline" class="phone-form">
              <mat-label>{{'MOBILE_PHONE' | translate}}</mat-label>
              <span matPrefix>+{{phoneUtil.getCountryCodeForRegion(countryForm.value.countryCode)}}</span>
              <input [formControl]="phoneForm" matInput type="tel" required [name]="'mobilephone'"
                     (focusin)="focusPhone(true)" (focusout)="focusPhone(false)" autocomplete="off">
            </mat-form-field>

          </div>
          <div class="pay-instructions" *ngIf="configurationStepType === 'PAYMENT_CONFIRMATION'">
            {{'PAY_INSTRUCTION_TEXT' | translate}}
          </div>
          <p class="instructions" *ngIf="configurationStepType === 'NO_CONFIRMATION'">
            {{'PHONE_INSTRUCTION_TEXT' | translate}}
          </p>
          <p class="instructions" *ngIf="configurationStepType === 'SMS_CONFIRMATION'">
            {{'SMS_INSTRUCTION_TEXT' | translate}}
          </p>
          <div class="mail-form" *ngIf="configurationStepType === 'PAYMENT_CONFIRMATION'">
            <div class="mat-form-field-flex" style="align-items: center">
              <p class="sub-text">{{'INVOICE_INSTRUCTION_MAIL_TEXT' | translate}}</p>
              <mat-slide-toggle [formControl]="invoiceToggleForm" color="blue"
                                (change)="mep.expanded = !mep.expanded"></mat-slide-toggle>
            </div>
            <mat-accordion>
              <mat-expansion-panel #mep [ngClass]="darkMode ? 'dark' : 'light'"
                                   [expanded]="invoiceToggleForm.value">
                <mat-form-field appearance="outline">
                  <mat-label>{{'MAIL_ADDRESS' | translate}}</mat-label>
                  <input [formControl]="invoiceMailForm" matInput [email]="true" required
                         placeholder="johndoe@taxibutler.com" autocomplete="off" name="email" >
                </mat-form-field>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </form>
        <div class="footer-buttons" [ngClass]="darkMode ? 'dark-background' : 'light-background'">
          <div [@pulsatingButton]="getCurrentState()" (@pulsatingButton.done)="nextState()" class="button-container">
            <button [disabled]="!isValid()" mat-button class="floating-button"
                    (click)="confirmPersonalInformation()">{{ payEnabled ? ('GO_TO_CHECKOUT' | translate) : ('GO_TO_OVERVIEW'  | translate) }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

