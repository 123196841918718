import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ThemeService } from '../../../services/theme.service';
import { StepperService } from '../../../services/stepper.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { GA4Events } from '../../../models/constants/GA4Events';

@Component({
  selector: 'app-sms-confirmation',
  templateUrl: './sms-confirmation.component.html',
  styleUrls: ['./sms-confirmation.component.scss']
})
export class SmsConfirmationComponent implements OnInit, OnDestroy {
  private stepperSubscription!: Subscription;
  @Input() next: () => void = () => { };
  @Input() screenHeight: number = 0;

  @ViewChildren('codeInput') codeInputElements?: QueryList<ElementRef>;

  public bookingDetails: any = {};
  public countdownSeconds: number | undefined;
  public countdownMinutes: number | undefined;

  private tripId: number | undefined;
  private readonly codeInputSize = 6;
  public inputRows: number[] = [...Array(this.codeInputSize).keys()];
  public codeArray: string[] = Array(this.codeInputSize).fill('');
  public verifying = true;
  private confirmBefore: string | undefined;
  public showExpired: boolean = false;
  public codeWrong = false;
  public tripCreationFailed = false;

  constructor(private stepperService: StepperService,
    private router: Router,
    private apiService: ApiService,
    public themeService: ThemeService,
    private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.setStepperSubscription();
    this.createSmsTrip();
    this.gaService.sendEvent(GA4Events.confirmBooking, 'true');
  }

  ngOnDestroy(): void {
    this.stepperSubscription.unsubscribe();
  }

  private setStepperSubscription(): void {
    this.stepperSubscription = this.stepperService.bookingDetails$.subscribe(bookingDetails => {
      this.bookingDetails = bookingDetails;
    });
  }

  private createSmsTrip(): void {
    this.apiService.createSmsTrip(this.bookingDetails)
      .then(response => {
        if (response.tripId) {
          this.confirmBefore = response.confirmBefore;
          this.doCountdown();
          this.verifying = false;
          this.tripId = response.tripId;
        }
      })
      .catch(() => {
          this.tripCreationFailed = true;
      });
  }

  onFocus(index: number): void {
    // If the focus element is the first one, do nothing
    if (index == 0) return;

    // If the value of the first input is empty, focus it.
    if (!this.codeArray[0] == null) {
      this.codeInputElements?.toArray()[0].nativeElement.focus();
    }

    // If value of a previous input is empty, focus it.
    // To remove if you don't wanna force user respecting the fields order.
    if (this.codeArray[index - 1] == null) {
      this.codeInputElements?.toArray()[index - 1].nativeElement.focus();
    }

    this.verifyCode();
  }

  splitNumber(event: any, index: number): void {
    let data = event.data || event.target.value; // Chrome doesn't get the e.data, it's always empty, fallback to value then.
    if (data == null) return; // Shouldn't happen, just in case.
    if (data.length === 1) return; // Normal behavior, not a paste action.

    this.popupNext(index, data);
  }

  popupNext(index: number, data: string): void {
    this.codeArray[index] = data[0]; // Apply first item to first input
    data = data.substring(1); // remove the first char.
    if (index < 5 && data.length) {
      // Do the same with the next element and next data
      this.popupNext(index + 1, data);
    } else {
      this.verifyCode();
    }
  };

  onKeyUp(event: any, index: number): void {
    // On Backspace or left arrow, go to the previous field.
    if ((event.keyCode === 8 || event.keyCode === 37) && index > 0) {
      this.codeInputElements?.toArray()[index - 1].nativeElement.select();
    } else if (event.keyCode !== 8 && index < 5) {
      this.codeInputElements?.toArray()[index + 1].nativeElement.select();
    }

    // If the target is populated to quickly, value length can be > 1
    if (event.target.value.length > 1) {
      this.splitNumber(event, index);
    }
  }

  bookAgain(): void {
    this.router.navigate([''], { queryParams: { retry: true }, queryParamsHandling: 'merge', replaceUrl: true });
    location.reload();
  }

  verifyCode(): void {
    if (!this.codeArray.includes('')) {
      const code = this.codeArray.reduce((sum: string, current: string) => sum + current, '');
      this.verifying = true;
      this.apiService.confirmSmsTrip(this.tripId!, code)
        .then(response => {
          if (response.redirectionUrl) {
            this.gaService.sendEvent(GA4Events.redirectMBT, 'true');
            window.location.href = response.redirectionUrl;
          }
        })
        .catch(() => {
          this.verifying = false;
          this.codeArray = Array(this.codeInputSize).fill('');
          this.codeWrong = true;
        });
    }
  }

  private doCountdown(): void {
    if (this.confirmBefore) {
      const diffTime = new Date(this.confirmBefore).getTime() - new Date().getTime();
      const minutes = Math.floor(diffTime / 1000 / 60);
      const seconds = Math.floor(diffTime / 1000 % 60);
      this.countdownMinutes = minutes;
      this.countdownSeconds = seconds;
      setTimeout(() => {
        this.showExpired = diffTime <= 0;
        this.doCountdown();
      }, 1000);
    }
  }
}
