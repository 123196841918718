import {Country} from '../Country';

function countries(): Country[] {
  return  [
    {countryCode: 'af', nativeName:'افغانستانAfghanestan'},
    { countryCode: 'al', nativeName: 'Shqipëria'},
    { countryCode: 'dz', nativeName: 'Dzayer'},
    { countryCode: 'as', nativeName: 'Samoa, Sāmoa'},
    { countryCode: 'ad', nativeName: 'Andorra'},
    { countryCode: 'ao', nativeName: 'Andorra'},
    { countryCode: 'ai', nativeName: ''},
    // { countryCode: 'aq',nativeName:''},
    { countryCode: 'ag', nativeName:'Antigua'},
    { countryCode: 'ar', nativeName:'Antigua'},
    { countryCode: 'am', nativeName:'Hayastán'},
    { countryCode: 'aw', nativeName:'Aruba'},
    { countryCode: 'au', nativeName:'Australia'},
    { countryCode: 'at', nativeName:'Österreich'},
    { countryCode: 'az', nativeName:'Azərbaycan'},
    { countryCode: 'bh', nativeName:'البحرينAl-Bahrayn'},
    { countryCode: 'bd', nativeName:'Bangladesh বাংলাদেশ'},
    { countryCode: 'bb', nativeName:'Barbados'},
    { countryCode: 'by', nativeName:'BielaruśБеларусь'},
    { countryCode: 'be', nativeName:'België, Belgique, Belgien'},
    { countryCode: 'bz', nativeName:'Belize'},
    { countryCode: 'bj', nativeName:'Bénin'},
    { countryCode: 'bm', nativeName:'Bermuda'},
    { countryCode: 'bt', nativeName:'Druk Yul, འབྲུག་ཡུལ'},
    { countryCode: 'bo', nativeName:'Wuliwya, Volívia, Buliwya, Bolivia'},
    { countryCode: 'bq', nativeName:'Bonaire, Sint Eustatius and Saba'},
    { countryCode: 'ba', nativeName:'Bosnia I Hercegovína, Босна и Херцеговина'},
    { countryCode: 'bw', nativeName:'Botswana'},
    // {countryName: 'Bouvet Island', countryCode: 'bv', nativeName:''},
    { countryCode: 'br', nativeName:'Brasil'},
    {countryCode: 'io', nativeName:'British Indian Ocean Territory'},
    {countryCode: 'vg', nativeName:'British Virgin Islands'},
    { countryCode: 'bn', nativeName:'Brunei بروني'},
    { countryCode: 'bg', nativeName:'Bulgariya, България'},
    { countryCode: 'bf', nativeName:'Burkina Faso'},
    { countryCode: 'mm', nativeName:'Myanma မြန်မာ'},
    { countryCode: 'bi', nativeName:'Burundi, Uburundi'},
    { countryCode: 'kh', nativeName:'Kampuchea កម្ពុជា'},
    { countryCode: 'cm', nativeName:'Cameroon, Cameroun'},
    { countryCode: 'ca', nativeName:'Canada'},
    { countryCode: 'cv', nativeName:'Cabo Verde'},
    { countryCode: 'ky', nativeName:'Cayman Islands'},
    { countryCode: 'cf', nativeName:'République Centrafricaine, Ködörösêse tî Bêafrîka'},
    { countryCode: 'td', nativeName:'Tchad, تشاد'},
    { countryCode: 'cl', nativeName:'Chile'},
    { countryCode: 'cn', nativeName:'Zhōngguó 中国'},
    { countryCode: 'cx', nativeName:'Christmas Island'},
    { countryCode: 'cc', nativeName:'Cocos (Keeling) Islands'},
    { countryCode: 'co', nativeName:'Colombia'},
    { countryCode: 'km', nativeName:'جزر القمر Comores Koromi'},
    { countryCode: 'ck', nativeName:'Cook Islands'},
    { countryCode: 'cr', nativeName:'Costa Rica'},
    { countryCode:'ci', nativeName:'Côte d\'Ivoire'},
    { countryCode: 'hr', nativeName:'Hrvatska'},
    { countryCode: 'cu', nativeName:'Cuba'},
    { countryCode: 'cw', nativeName:'Curaçao'},
    { countryCode: 'cy', nativeName:'Κύπρος Kıbrıs'},
    { countryCode: 'cz', nativeName:'Česko'},
    { countryCode: 'cd', nativeName:'République démocratique du Congo, Republíki ya Kongó, Demokratíki Jamhuri ya Kidemokrasia ya Kongo'},
    { countryCode: 'dk', nativeName:'Danmark'},
    { countryCode: 'dj', nativeName:'جيبوتي Djibouti'},
    { countryCode: 'dm', nativeName:'Dominicana'},
    { countryCode: 'do', nativeName:'República Dominicana'},
    { countryCode: 'ec', nativeName:'Ecuador'},
    { countryCode: 'eg', nativeName:'مصرMisr'},
    { countryCode: 'sv', nativeName:'El Salvador'},
    { countryCode: 'gq', nativeName:'Guinea Ecuatorial'},
    { countryCode: 'er', nativeName:'Iritriya إرتريا Ertra'},
    { countryCode: 'ee', nativeName:'Eesti'},
    { countryCode: 'et', nativeName:'Ityop\'ia ኢትዮጵያ'},
    { countryCode: 'fk', nativeName:'Falkland Islands'},
    { countryCode: 'fo', nativeName:'Faroe Islands'},
    { countryCode: 'fm', nativeName:'Federated States of Micronesia'},
    { countryCode: 'fj', nativeName:'Fiji, Viti, फ़िजी'},
    { countryCode: 'fi', nativeName:'Suomi, Finland'},
    { countryCode: 'fr', nativeName:'France'},
    { countryCode: 'gf', nativeName:'Guyane'},
    { countryCode: 'pf', nativeName:'Polynésie française Pōrīnetia Farāni'},
    // {countryName: 'French Southern Territories', countryCode: 'tf', nativeName:''},
    { countryCode: 'ga', nativeName:'République gabonaise'},
    { countryCode: 'ge', nativeName:'Sak\'art\'velo საქართველო'},
    { countryCode: 'de', nativeName:'Deutschland'},
    { countryCode: 'gh', nativeName:'Gaana, Ghana, Gana, Gana'},
    { countryCode: 'gi', nativeName:'Gibraltar'},
    { countryCode: 'gr', nativeName:'Hellas Ελλάς'},
    { countryCode: 'gl', nativeName:'Kalaallit Nunaat Grønland'},
    { countryCode: 'gd', nativeName:'Grenada'},
    { countryCode: 'gp', nativeName:'Gwadloup'},
    { countryCode: 'gu', nativeName:'Guåhan'},
    { countryCode: 'gt', nativeName:'Guatemala'},
    { countryCode: 'gg', nativeName:'Guernesey Giernési'},
    { countryCode: 'gn', nativeName:'Guinée, Gine, Gine'},
    { countryCode: 'gw', nativeName:'República da Guiné-Bissau'},
    { countryCode: 'gy', nativeName:'Guyana'},
    { countryCode: 'ht', nativeName:'Haïti, Ayiti'},
    // {countryName: 'Heard Island and McDonald Islands', countryCode: 'hm', nativeName:''},
    { countryCode: 'hn', nativeName:'Honduras'},
    { countryCode: 'hk', nativeName:'Hong Kong, Heung Gong, 香港'},
    { countryCode: 'hu', nativeName:'Magyarország'},
    { countryCode: 'is', nativeName:'Ísland'},
    { countryCode: 'in', nativeName:'Bharôt ভাৰত, Bharôt ভারত, India, Bhārat ભારત, Bhārat भारत, Bhārata ಭಾರತ, Bhārat भारत, Bhāratam ഭാരതം, Bhārat भारत, Bhārat भारत, Bharôtô ଭାରତ, Bhārat ਭਾਰਤ, Bhāratam भारतम्, Bārata பாரதம், Bhāratadēsam భారత దేశం'},
    { countryCode: 'id', nativeName:'Indonesia'},
    { countryCode: 'ir', nativeName:'Īrān ایران'},
    { countryCode: 'iq', nativeName:'Al-\'Iraq العراق'},
    { countryCode: 'ie', nativeName:'Ireland, Éire'},
    { countryCode: 'im', nativeName:'Mannin, Ellan Vannin '},
    { countryCode: 'il', nativeName:'Israʼiyl إسرائيل, Yisra\'el ישראל'},
    { countryCode: 'it', nativeName:'Italia'},
    { countryCode: 'jm', nativeName:'Jamaica'},
    { countryCode: 'jp', nativeName:'Nippon 日本'},
    { countryCode: 'je', nativeName:'Bailliage de Jersey Bailliage dé Jèrri'},
    { countryCode: 'jo', nativeName:'Al-\'Urdun الأردن'},
    { countryCode: 'kz', nativeName:'Qazaqstan Қазақстан, Kazakhstán Казахстан'},
    { countryCode: 'ke', nativeName:'Kenya'},
    { countryCode: 'ki', nativeName:'Kiribati'},
    { countryCode: 'xk', nativeName:'Kosova, Косово'},
    { countryCode: 'kw', nativeName:'Dawlat ul-Kuwayt دولة الكويت'},
    { countryCode: 'kg', nativeName:'Kyrgyzstan Кыргызстан, Kirgizija Киргизия'},
    { countryCode: 'la', nativeName:'Lao ປະເທດລາວ'},
    { countryCode: 'lv', nativeName:'Latvija'},
    { countryCode: 'lb', nativeName:'Lubnān لبنان, Liban'},
    { countryCode: 'ls', nativeName:'Lesotho'},
    { countryCode: 'lr', nativeName:'Republic of Liberia'},
    { countryCode: 'ly', nativeName:'Libya, Lībiyā ليبيا'},
    { countryCode: 'li', nativeName:'Liechtenstein'},
    { countryCode: 'lt', nativeName:'Lietuva'},
    { countryCode: 'lu', nativeName:'Luxembourg, Luxemburg, Lëtezebuerg'},
    { countryCode: 'mo', nativeName:'澳門'},
    { countryCode: 'mk', nativeName:'Μακεδονία'},
    { countryCode: 'mg', nativeName:'Madagascar, Madagasikara'},
    { countryCode: 'mw', nativeName:'Malaŵi, Malawi'},
    { countryCode: 'my', nativeName:'Mǎláixīyà 马来西亚, Malaysia, மலேசியா'},
    { countryCode: 'mv', nativeName:'Dhivehi Raajje'},
    { countryCode: 'ml', nativeName:'Mali, Mali'},
    { countryCode: 'mt', nativeName:'Malta, Malta'},
    { countryCode: 'mh', nativeName:'Marshall Islands, Aorōkin M̧ajeļ'},
    { countryCode: 'mq', nativeName:'Martinique'},
    { countryCode: 'mr', nativeName:'Muritan / Agawec, Mūrītānyā موريتانيا'},
    { countryCode: 'mu', nativeName:'Mauritius, Maurice, Moris'},
    { countryCode: 'yt', nativeName:'Mayotte'},
    { countryCode: 'mx', nativeName:'Mēxihco, México'},
    { countryCode: 'md', nativeName:'Moldova'},
    { countryCode: 'mc', nativeName:'Monaca, Múnegu'},
    { countryCode: 'mn', nativeName:'Mongol Uls Монгол Улс'},
    { countryCode: 'me', nativeName:'Crna Gora Црна Гора'},
    { countryCode: 'ms', nativeName:'Montserrat'},
    { countryCode: 'ma', nativeName:'Al-maɣréb المغرب, Amerruk / Elmeɣrib'},
    { countryCode: 'mz', nativeName:'Moçambique'},
    { countryCode: 'na', nativeName:'Namibia, Namibia, Namibia, Namibia, Namibia'},
    { countryCode: 'nr', nativeName:'Repubrikin Naoero'},
    { countryCode: 'np', nativeName:'Nepāl नेपाल'},
    { countryCode: 'nl', nativeName:'Nederland'},
    { countryCode: 'nc', nativeName:'Nouvelle-Calédonie'},
    { countryCode: 'nz', nativeName:'New Zealand, Aotearoa'},
    { countryCode: 'ni', nativeName:'Nicaragua'},
    { countryCode: 'ne', nativeName:'Niger'},
    { countryCode: 'ng', nativeName:'Nigeria, Nijeriya, Naigeria, Nàìjíríà'},
    { countryCode: 'nu', nativeName:'Niuē'},
    { countryCode: 'nf', nativeName:'Norfolk Island'},
    { countryCode: 'kp', nativeName:'조선민주주의인민공화국 (Korean) Chosŏn Minjujuŭi Inmin Konghwaguk'},
    { countryCode: 'mp', nativeName:'Northern Mariana Islands'},
    { countryCode: 'no', nativeName:'Norge'},
    { countryCode: 'om', nativeName:'‘Umān عُمان'},
    { countryCode: 'pk', nativeName:'Pākistān پاکستان'},
    { countryCode: 'pw', nativeName:'Palau, Belau'},
    { countryCode: 'ps', nativeName:'Filasṭīn فلسطين'},
    { countryCode: 'pa', nativeName:'Panamá'},
    { countryCode: 'pg', nativeName:'Papua New Guinea, Papua Niugini, Papua Giugini'},
    { countryCode: 'py', nativeName:'Paraguái, Paraguay'},
    { countryCode: 'pe', nativeName:'Piruw, Perú'},
    { countryCode: 'ph', nativeName:'Philippines, Pilipinas'},
    // { countryCode: 'pn', nativeName:''},
    { countryCode: 'pl', nativeName:'Polska'},
    { countryCode: 'pt', nativeName:'Portugal'},
    { countryCode: 'pr', nativeName:'Puerto Rico'},
    { countryCode: 'qa', nativeName:'Qaṭar قطر'},
    { countryCode: 'cg', nativeName:'République du Congo Repubilika ya Kôngo Republíki ya Kongó' },
    { countryCode: 're', nativeName:'Réunion'},
    { countryCode: 'ro', nativeName:'România'},
    { countryCode: 'ru', nativeName:'Rossiâ Россия'},
    { countryCode: 'rw', nativeName:'Rwanda'},
    { countryCode: 'bl', nativeName:'Saint-Barthélemy'},
    { countryCode: 'sh', nativeName:'Saint Helena'},
    { countryCode: 'kn', nativeName:'Saint Kitts and Nevis'},
    { countryCode: 'mf', nativeName:'Saint Martin'},
    { countryCode: 'pm', nativeName:'Saint Pierre and Miquelon'},
    { countryCode: 'vc', nativeName:'Saint Vincent and the Grenadines'},
    { countryCode: 'ws', nativeName:'Samoa, Sāmoa'},
    { countryCode: 'sm', nativeName:'San Marino'},
    { countryCode: 'st', nativeName:'São Tomé e Principe'},
    { countryCode: 'sa', nativeName:'Al-‘Arabiyyah as Sa‘ūdiyyah المملكة العربية السعودية'},
    { countryCode: 'sn', nativeName:'Sénégal'},
    { countryCode: 'rs', nativeName:'Srbija Србија'},
    { countryCode: 'sc', nativeName:'Seychelles, Seychelles, Sesel'},
    { countryCode: 'sl', nativeName:'Sierra Leone'},
    { countryCode: 'sg', nativeName:'Singapore, Singapura, 新加坡, சிங்கப்பூர்'},
    { countryCode: 'sx', nativeName:'Sint Maarten'},
    { countryCode: 'sk', nativeName:'Slovensko'},
    { countryCode: 'si', nativeName:'Slovenija'},
    { countryCode: 'sb', nativeName:'Solomon Islands, Solomon Aelan'},
    { countryCode: 'so', nativeName:'Soomaaliya aş-Şūmāl, الصومال'},
    { countryCode: 'za', nativeName:'Suid-Afrika, South Africa, iNingizimu Afrika, uMzantsi Afrika, Afrika-Borwa, Afrika Borwa, Aforika Borwa, Afurika Tshipembe, Afrika Dzonga, iNingizimu Afrika, iSewula Afrika'},
    // {countryName: 'South Georgia and the South Sandwich Islands', countryCode: 'gs', nativeName:''},
    { countryCode: 'ss', nativeName:'South Sudan, Paguot Thudän, Sudan Kusini'},
    { countryCode: 'kr', nativeName:'대한민국 Daehan Minguk'},
    { countryCode: 'es', nativeName:'España, Espanya, Espainia'},
    { countryCode: 'lk', nativeName:'Sri Lankā ශ්‍රී ලංකාව இலங்கை'},
    { countryCode: 'lc', nativeName:'Saint Lucia'},
    { countryCode: 'sd', nativeName:'As-Sudan السودان'},
    { countryCode: 'sr', nativeName:'Republiek Suriname'},
    { countryCode: 'sj', nativeName:''},
    { countryCode: 'sz', nativeName:'Svalbard and Jan Mayen'},
    { countryCode: 'se', nativeName:'Sverige'},
    { countryCode: 'ch', nativeName:'Suisse, Schweiz, Svizzera, Svizra'},
    { countryCode: 'sy', nativeName:'Suriyah سورية'},
    { countryCode: 'tw', nativeName:'中華民國 Zhōnghuá Mínguó '},
    { countryCode: 'tj', nativeName:'Tojikistan Тоҷикистон'},
    { countryCode: 'tz', nativeName:'Tanzania'},
    { countryCode: 'th', nativeName:'Mueang Thai, Prathet Thai, Ratcha-anachak Thai เมืองไทย, ประเทศไทย, ราชอาณาจักรไทย'},
    { countryCode: 'bs', nativeName:'The Bahamas'},
    { countryCode: 'gm', nativeName:'Republic of The Gambia'},
    { countryCode: 'tl', nativeName:'Democratic Republic of Timor-Leste República Democrática de Timor-Leste Repúblika Demokrátika de Timór-Leste'},
    { countryCode: 'tg', nativeName:'Togo, Togo, Togo'},
    { countryCode: 'tk', nativeName:'Tokelau'},
    { countryCode: 'to', nativeName:'Tonga'},
    { countryCode: 'tt', nativeName:'Trinidad and Tobago'},
    { countryCode: 'tn', nativeName:'Tunes, تونس'},
    { countryCode: 'tr', nativeName:'Türkiye Cumhuriyeti'},
    { countryCode: 'tm', nativeName:'Türkmenistan'},
    { countryCode: 'tc', nativeName:'urks and Caicos Islands'},
    { countryCode: 'tv', nativeName:'Tuvalu'},
    { countryCode: 'ug', nativeName:'Uganda'},
    { countryCode: 'ua', nativeName:'Ukraїna Україна'},
    { countryCode: 'ae', nativeName:'Al-’Imārat Al-‘Arabiyyah Al-Muttaḥidah الإمارات العربيّة المتّحدة'},
    { countryCode: 'gb', nativeName:'United Kingdom, Y Deyrnas Unedig, Rìoghachd Aonaichte, Ríocht Aontaithe'},
    // {countryName: 'United States Minor Outlying Islands', countryCode: 'um', nativeName:''},
    { countryCode: 'us', nativeName:'United States'},
    { countryCode: 'uy', nativeName:'Uruguay'},
    { countryCode: 'vi', nativeName:'US Virgin Islands'},
    { countryCode: 'uz', nativeName:'O‘zbekiston Ўзбекистон'},
    { countryCode: 'vu', nativeName:'Vanuatu'},
    { countryCode: 'va', nativeName:'Città del Vaticano, Civitas Vaticana'},
    { countryCode: 've', nativeName:'Venezuela'},
    { countryCode: 'vn', nativeName:'Việt Nam'},
    { countryCode: 'wf', nativeName:'Wallis-et-Futuna Uvea mo Futuna'},
    { countryCode: 'eh', nativeName:'ة الصحراء الغربية Taneẓroft Tutrimt Sáhara Occidenta'},
    { countryCode: 'ye', nativeName:'Al-Yaman اليمن'},
    { countryCode: 'zm', nativeName:'Zambia'},
    { countryCode: 'zw', nativeName:'Zimbabwe'},
    { countryCode: 'ax', nativeName: 'Ahvenanmaa'}
  ];

}

  export default countries;
