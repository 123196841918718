<div class="container" [ngClass]="isDark ? 'dark' : 'light'" xmlns="http://www.w3.org/1999/html">
  <h6>{{ 'CS' | translate }}</h6>

  <p>{{ 'CS_TEXT_1' | translate }}</p>

  <p>{{ 'CS_TEXT_2' | translate }}</p>

  <mat-radio-group aria-label="Select an option" [(ngModel)]="cookiesAccepted">
    <div class="radio-card" [ngStyle]="{'border-color': cookiesAccepted === 'all' ? proColor : '#0000001F'}" (click)="setCookies('all')">
      <mat-radio-button value="all" (change)="setCookies($event.value)">{{ 'CS_TEXT_3' | translate }}</mat-radio-button>
      <ul class="dashed">
        <li>{{ 'CS_TEXT_4' | translate }}</li>
        <li>{{ 'CS_TEXT_5' | translate }}</li>
        <li>{{ 'CS_TEXT_6' | translate }}</li>
      </ul>
    </div>

    <div class="radio-card" [ngStyle]="{'border-color': cookiesAccepted !== 'all' ? proColor : '#0000001F'}" (click)="setCookies('functional')" >
      <mat-radio-button (change)="setCookies($event.value)" value="functional">{{ 'CS_TEXT_7' | translate }}</mat-radio-button>
      <ul class="dashed">
        <li>{{ 'CS_TEXT_8' | translate }}</li>
        <li>{{ 'CS_TEXT_8_1' | translate }}</li>

      </ul>
    </div>
  </mat-radio-group>

  <p> {{ 'CS_TEXT_9' | translate }}  <a [ngStyle]="{'color': proColor}" href="{{ this.getNoticeLink() }}">[LINK]</a>.</p>

  <p class="bold">{{ 'CS_TEXT_10' | translate }}</p>
  <p>{{ 'CS_TEXT_11' | translate }}</p>

  <ul class="dashed-url">
    <li><a [ngStyle]="{'color': proColor}" href="https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop" target="_blank">Chrome</a></li>
    <li><a [ngStyle]="{'color': proColor}" href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox" target="_blank">Firefox</a></li>
    <li><a [ngStyle]="{'color': proColor}" href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Internet Explorer</a></li>
    <li><a [ngStyle]="{'color': proColor}" href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank">Edge</a></li>
    <li><a [ngStyle]="{'color': proColor}" href="https://support.apple.com/en-us/HT201265" target="_blank">Safari (iOS)</a></li>
    <li><a [ngStyle]="{'color': proColor}" href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank">Safari (macOS)</a></li>
  </ul>

  <h6>{{'CS_TEXT_12' | translate }}</h6>

  <p class="bold">selectedLanguage</p>
  <p>{{ 'CS_TEXT_13' | translate }}</p>
  <p class="bold">acceptedCookies</p>
  <p>{{ 'CS_TEXT_14' | translate }}</p>

  <p class="bold">bookingDetails</p>
  <p>{{ 'CS_TEXT_15' | translate }}</p>

  <p class="bold">adyen-checkout__session</p>
  <p>{{ 'CS_TEXT_16' | translate}}</p>

  <h6>{{ 'CS_TEXT_17' | translate }}</h6>

  <p class="bold">{{ 'CS_TEXT_18' | translate }}</p>

  <p class="bold-margin">_ga</p>
  <p>{{ 'CS_TEXT_19' | translate }}</p>

  <p class="bold">ga*</p>
  <p> {{'CS_TEXT_19' | translate }}</p>

  <p class="bold">{{ 'CS_TEXT_20' | translate }}</p>

  <p class="bold-margin">_hjAbsoluteSessionInProgress</p>
  <p>{{ 'CS_TEXT_21' | translate }}</p>

  <p class="bold">hjSession*</p>
  <p>{{ 'CS_TEXT_22' | translate }}</p>

  <p class="bold">hjIncludedInSessionSample*</p>
  <p>{{ 'CS_TEXT_23' | translate }}</p>

  <p class="bold">_hjFirstSeen</p>
  <p>{{'CS_TEXT_24' | translate }}</p>

  <p class="bold">hjSessionUser*</p>
  <p class="end">{{ 'CS_TEXT_25' | translate }}</p>


</div>
