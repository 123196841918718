import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LayoutStructureComponent} from './components/layout-structure/layout-structure.component';
import {HomeComponent} from './pages/home/home.component';
import {AuthGuardService} from './services/auth-guard.service';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import { CookiePageComponent } from './pages/cookie-page/cookie-page.component';
import {CheckoutComponent} from './pages/checkout/checkout.component';
import {CheckoutGuardService} from './services/checkout-guard.service';
import {InvoiceEmailComponent} from './pages/invoice-email/invoice-email.component';
import {InvoiceGuardService} from './services/invoice-guard.service';
import { LegalDocumentPageComponent } from './pages/legal-document-page/legal-document-page.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutStructureComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'cookies',
        component: CookiePageComponent
      },
      {
        path: 'document/:type',
        component: LegalDocumentPageComponent
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [CheckoutGuardService]
      },
    ]
  },
  {
    path: 'invoice',
    component: InvoiceEmailComponent,
    canActivate: [InvoiceGuardService]
  },
  {
    path: '404',
    component: NotFoundComponent
  },

  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
