import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OverlayContainer} from '@angular/cdk/overlay';
import {ThemeService} from '../../../services/theme.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit, OnDestroy {
  themeServiceSubscription: Subscription | undefined;

  public proColor: string = '#ffcc00';

  constructor(
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private overlay: OverlayContainer,
    private themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.setThemeSubscription();
  }

  private setThemeSubscription(): void {
    this.themeServiceSubscription = this.themeService.themeDetails$.subscribe(themeDetails => {
      this.proColor = themeDetails.primaryColor;
    });
  }

  ngOnDestroy(): void {
    if (this.themeServiceSubscription) {
      this.themeServiceSubscription.unsubscribe();
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
