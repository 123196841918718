import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import Countries from '../../models/constants/Countries';
import {SelectCountryService} from '../../services/select-country.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit {
  searchValue: string = '';
  public countries = Countries();
  @ViewChild('search') searchElement?: ElementRef;


  constructor(private selectCountryService: SelectCountryService) {
  }

  onSearch(): void {
    this.selectCountryService.search(this.searchValue.trim());
  }

  public focus(): void {
    document.getElementById('search')?.focus();
  }

  ngAfterViewInit(): void {
    this.focus();
  }
}
