import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'dateTranslate'
})
export class DateTranslatePipe implements PipeTransform {
  private selectedLang: string | undefined;

  constructor(private i18nService: TranslateService) {
  }

  transform(value: Date, ...args: unknown[]): unknown {
    if (!value) {
      return '';
    }

    const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY',
      'THURSDAY', 'FRIDAY', 'SATURDAY'];

    const months = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL',
      'MAY', 'JUNE', 'JULY', 'AUGUST',
      'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];

    const day = this.localizeString(days[new Date(value).getDay()]);
    const month = this.localizeString(months[new Date(value).getMonth()]);
    const dateNr = new Date(value).getDate();

    return day + ', ' + month + ' ' + dateNr;
  }

  localizeString(value: string): string {
    return this.i18nService.instant(value);
  }
}
