<app-layout [ngStyle]="{'visibility': portrait ? 'visible' : 'hidden'}">
  <mat-sidenav-container>
    <mat-sidenav class="mat-elevation-z3"
                 #sidenav
                 role="navigation"
                 mode="over"
                 [fixedInViewport]="false">
      <h5>{{ this.title }}</h5>
      <app-sidenav-list></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="darkMode ? 'main-dark' : 'main-light'">
      <app-top-bar (sidenavToggle)="sidenav.toggle()"></app-top-bar>
      <main [ngClass]="darkMode ? 'main-dark' : 'main-light'">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>

<div [ngStyle]="{'visibility': portrait ? 'hidden' : 'visible'}">
  <div class="inner-div">
    <img src="assets/images/taxi_happy.png" alt="sad-taxi">
    <p [ngClass]="[!darkMode ? 'dark-text' : 'light-text', 'text']"> {{ 'PLEASE_ROTATE' | translate }}</p>
  </div>
</div>
