import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnChanges, OnDestroy {
  @Input() steps: any[] = [{}];
  @Input() currentStep = 0;

  private themeSubscription!: Subscription;

  public progress = 0;
  public darkMode!: boolean;

  constructor(private themeService: ThemeService) {
    this.themeSubscription = this.themeService.isDarkTheme$.subscribe(darkMode => this.darkMode = darkMode);
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['steps']) {
      this.steps = changes['steps'].currentValue;
    }
    if (changes['currentStep']) {
      this.currentStep = changes['currentStep'].currentValue;
    }
    this.setProgress();
  }

  setProgress(): void {
    this.progress = ((this.currentStep + 1) / this.steps.length) * 100;
  }

  public getStepDescription(currentStep: number): string {
    const hasPayment = this.steps.filter(e => e.description === 'PAY_FOR_YOUR_BOOKING').length > 0;
    if (hasPayment && this.steps[currentStep].description == 'BOOKING_OVERVIEW') {
      return 'YOUR_BOOKING';
    } else {
      return this.steps[currentStep].description;
    }
  }

}
