import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {StepperService} from '../../services/stepper.service';
import {ApiService} from '../../services/api.service';
import {Subscription} from 'rxjs';
import {SelectCountryService} from '../../services/select-country.service';
import {LegalService} from '../../services/legal.service';
import { ThemeService } from '../../services/theme.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy, AfterViewInit {
  private stepperSubscription!: Subscription;
  private configurationSubscription!: Subscription;

  public onConfirmationScreen: Boolean = false;
  public selectCountryOpen: boolean = false;

  public isSmallScreen = true;

  searchOpen: boolean = false;

  @Output() public sidenavToggle = new EventEmitter();
  public displayHamburger = true;
  public name = '';
  private amountOfSteps: number = 0;
  private selectCountrySub!: Subscription;
  private searchOpenSub!: Subscription;
  private viewLegalOpenSub!: Subscription;
  public viewLegalOpen: boolean = false;
  public isDarkMode: boolean = true;
  private themeSubscription!: Subscription;
  public darkParam: boolean = true;
  public externalOpen: boolean = false;

  constructor(private apiService: ApiService,
              private stepperService: StepperService,
              private SelectCountryService: SelectCountryService,
              private legalService: LegalService,
              private themeService: ThemeService,
              private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.darkParam = this.route.snapshot.queryParams['d']! === 'true';
    this.setStepperSubscription();
    this.setConfigurationSubscription();
    this.setCountrySubscription();
    this.setSearchBarOpenSubscription();
    this.setLegalSubscription();
    this.setThemeSubscription();

  }

  ngOnDestroy(): void {
    this.stepperSubscription.unsubscribe();
    this.configurationSubscription.unsubscribe();
    this.selectCountrySub.unsubscribe();
    this.searchOpenSub.unsubscribe();
    this.viewLegalOpenSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.isSmallScreen = window.innerWidth < 600;
  }

  private setSearchBarOpenSubscription(): void {
    this.searchOpenSub = this.SelectCountryService.searchOpen$.subscribe(isOpen => {
      this.searchOpen = isOpen;
    });
  }

  private setCountrySubscription(): void {
    this.selectCountrySub = this.SelectCountryService.selectCountryOpen$.subscribe(isOpen => {
      this.selectCountryOpen = isOpen;
    });
  }

  private setLegalSubscription(): void {
    this.viewLegalOpenSub = this.legalService.viewLegalOpen$.subscribe(open => this.viewLegalOpen = open);
  }

  private setConfigurationSubscription(): void {
    this.configurationSubscription = this.apiService.butlerConfiguration$.subscribe(configuration => {
      if (configuration) {
        this.name = configuration.venueDetails.name;
        this.amountOfSteps = configuration.configurationSteps.length;
      }
    });
  }

  private setStepperSubscription(): void {
    this.stepperSubscription = this.stepperService.index$.subscribe(index => {
      this.displayHamburger = index < 0 || this.viewLegalOpen || this.externalOpen;
      if (this.amountOfSteps !== 0) {
        this.onConfirmationScreen = this.amountOfSteps - 1 === index;
      }
    });
  }

  public displayBackArrow(): boolean {
    return (!this.displayHamburger && !this.onConfirmationScreen && !window.location.pathname.includes('checkout')) || this.viewLegalOpen || this.externalOpen;
  }

  public onToggleSidenav(): void {
    this.sidenavToggle.emit();
  }

  public previous(): void {
    this.stepperService.previous();
  }

  public closeSearch(): void {
    this.SelectCountryService.setSearchOpen(false);
    this.SelectCountryService.resetSearch();
  }

  openSearch(): void {
    this.SelectCountryService.setSearchOpen(true);
  }

  private setThemeSubscription() {
    this.themeSubscription = this.themeService.isDarkTheme$.subscribe(darkMode => this.isDarkMode = darkMode);
  }
}
