<div *ngIf="configuration && (currentStep || isSmallScreen) && !viewLegalOpen"
     [hidden]="viewLegalOpen"
     [ngClass]="[darkMode ? 'dark-background' : 'light-background', isSmallScreen ? 'for-small-screen' : 'for-big-screen']">
  <app-stepper [steps]="configuration.configurationSteps"
               [currentStep]="this.index >= 0 ? this.index : 0"
               *ngIf="(!isSmallScreen || currentStep !== 'DESTINATION') && !selectCountryOpen">
  </app-stepper>
  <div *ngIf="(currentStep === 'VEHICLE_TYPE' || !currentStep ) && isSmallScreen "
       [ngClass]="currentStep === 'VEHICLE_TYPE' ? 'vehicle-type-map' : index === -1 ? 'foo-map' : 'default-map'">
    <app-map></app-map>
  </div>
  <div *ngIf="!currentStep" [ngClass]="isSmallScreen && !toastVisible ? 'on-map-without-toast' : 'on-map-with-toast'">
    <app-where-to-card (click)="next()"></app-where-to-card>
    <app-cookie-toast *ngIf="toastVisible"></app-cookie-toast>
  </div>
  <ng-container *ngIf="currentStep">
    <ng-container [ngSwitch]="currentStep">
      <app-destination [screenHeight]="screenHeight - (isSmallScreen ? (isMobile() ? 0 : 203) : 282)" [next]="next"
                       *ngSwitchCase="'DESTINATION'">
      </app-destination>
      <app-vehicle-type
        [screenHeight]="isSmallScreen ? screenHeight - parseInt((screenHeight * .7).toFixed(0)) - 26 : 0"
        [ngClass]="isSmallScreen ? 'small-screen-bottom' : 'big-screen-bottom'" [next]="next"
        *ngSwitchCase="'VEHICLE_TYPE'">
      </app-vehicle-type>
      <ng-container>
        <app-personal-information [payEnabled]="configurationStepType === 'PAYMENT_CONFIRMATION'"
                                  [hidden]="selectCountryOpen" [screenHeight]="isSmallScreen ? screenHeight - (isMobile() ? 0 : 161) : 0"
                                  [ngClass]="isSmallScreen ? 'small-screen-bottom' : 'big-screen-bottom'"
                                  [next]="next" *ngSwitchCase="'PERSONAL_INFORMATION'">
        </app-personal-information>
      </ng-container>

      <ng-container *ngIf="selectCountryOpen">
        <app-select-country *ngSwitchCase="'PERSONAL_INFORMATION'"
                            style="width: 100%; height: 100%;"></app-select-country>
      </ng-container>
      <ng-container>
        <app-booking-overview [screenHeight]="screenHeight - 128" 
                              [hidden]="viewLegalOpen"
                              [ngClass]="isSmallScreen ? 'small-screen-bottom' : 'big-screen-bottom'"
                              [payEnabled]="configurationStepType === 'PAYMENT_CONFIRMATION'" [next]="next"
                              *ngSwitchCase="'BOOKING_OVERVIEW'">
        </app-booking-overview>
      </ng-container>

      <ng-container>
        <app-no-confirmation [screenHeight]="screenHeight"
                             [ngClass]="isSmallScreen ? 'small-screen-bottom' : 'big-screen-bottom'"
                             [next]="next" *ngSwitchCase="'NO_CONFIRMATION'">
        </app-no-confirmation>
      </ng-container>
      <ng-container>
        <app-sms-confirmation [screenHeight]="screenHeight"
                              [ngClass]="isSmallScreen ? 'small-screen-bottom' : 'big-screen-bottom'"
                              [next]="next" *ngSwitchCase="'SMS_CONFIRMATION'">
        </app-sms-confirmation>
      </ng-container>

      <ng-container>
        <app-adyen-payment *ngSwitchCase="'PAYMENT_CONFIRMATION'"></app-adyen-payment>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<div *ngIf="!isSmallScreen && !viewLegalOpen"
     style="max-height: calc(100% - 8px)"
     [ngClass]="!currentStep ? 'map-container-fullscreen' : 'map-container'" class="map-container">
  <app-where-to-card *ngIf="!currentStep" style="margin: 10px; width: 400px; position: absolute; z-index: 1"
                     (click)="next()"></app-where-to-card>
  <div class="on-map-cookie">
    <app-cookie-toast *ngIf="toastVisible && index < 0" class="on-map-cookie"></app-cookie-toast>
  </div>
  <app-map></app-map>
</div>

<ng-container *ngIf="viewLegalOpen">
  <app-legal-document style="z-index: 110"></app-legal-document>
</ng-container>
