import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceGuardService implements CanActivate{

  private authenticated = false;
  private loadingSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSource.asObservable();

  constructor(private router: Router,
              private apiService: ApiService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const params = route.queryParamMap;

    if (!params.get('t') || !params.get('k')) {
      this.router.navigate(['404']).then();
      return false;
    }

    return new Promise<boolean>(resolve => {
      this.loadingSource.next(true);
      this.apiService.getThemeConfiguration(String(params.get('t')), String(params.get('k')))
        .then(authenticated => {
          this.loadingSource.next(false);
          this.authenticated = authenticated;
          if (authenticated) {
            resolve(authenticated);
          } else {
            this.router.navigate(['404']).then();
          }
        });
    });
  }
}
