import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { Theme } from '../../models/Theme';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-cookie-notice-viewer',
  templateUrl: './cookie-notice-viewer.component.html',
  styleUrls: ['./cookie-notice-viewer.component.scss']
})
export class CookieNoticeViewerComponent implements OnInit, OnDestroy {

  private themeServiceSubscription?: Subscription;
  public pdfSrc!: any;
  public proColor: string = '#ffcc00';
  public isDarkMode: boolean = false;

  constructor(private apiService: ApiService,
              private themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.loadPdf();

    this.themeServiceSubscription = this.themeService.themeDetails$.subscribe(themeDetails => {
      this.proColor = themeDetails.primaryColor;
      this.isDarkMode = themeDetails.theme === Theme.DARK;
    });
  }

  ngOnDestroy(): void {
    this.themeServiceSubscription!.unsubscribe();
  }

  private loadPdf(): void {
    this.apiService.getCookieNoticeDocument().subscribe((response: any) => {
      const blob = new Blob([response.body], {type: 'application/pdf'});
      this.pdfSrc = window.URL.createObjectURL(blob);
    });
  }

  public savePdf(): void {
    const link = document.createElement('a');
    link.href = this.pdfSrc;
    link.download = this.getFileName();
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    setTimeout(function() {
      document.body.removeChild(link);
    }, 100);
  }

  getFileName(): string {
    const baseName = window.location.hostname.includes('venuebutler') ? 'Venue Butler' : 'Taxi Butler';
    let date: string = new Date().getFullYear().toString();
    return 'Cookie policy ' + date + ' - ' + baseName + '.pdf';
  }
}
