<mat-card class="mat-elevation-z0" [ngClass]="isDarkMode ? 'dark-card' : 'light-card'">
  <mat-card-subtitle class="text-plan"
                     [ngStyle]="{'color': this.proColor}">{{ "PLAN_YOUR_BOOKING" | translate}}</mat-card-subtitle>
  <mat-divider [ngStyle]="{'border-top-color': this.proColorOpacity}"></mat-divider>
  <div class="texts">
    <div [ngClass]="[isDarkMode ? 'date-text-dark' : 'date-text-light', 'subtitle-1', 'date-text']"> {{"PICKUP_DATE" | translate }} : </div>
    <div [ngClass]="[isDarkMode ? 'time-text-dark' : 'time-text-light', 'subtitle-1', 'time-text']"
         class="time-text subtitle-1"> {{"PICKUP_TIME" | translate }}:
    </div>
  </div>

  <div class="input-fields">
    <input [ngClass]="[isDarkMode ? 'planner-date-dark' : 'planner-date-light', 'planner-date']"
           [ngStyle]="{'border-color': this.proColor}" matInput
           min="{{minDate|date:'yyyy-MM-dd'}}"
           max="{{maxDate|date:'yyyy-MM-dd'}}"
           [(ngModel)]="date"
           (change)="onChangeDate()"
           type="date">
    <input [ngClass]="[isDarkMode ? 'planner-time-dark' : 'planner-time-light', 'planner-time']"
           [ngStyle]="{'border-color': this.proColor}"
           [(ngModel)]="time" matInput type="time"
           (change)="onChangeTime()">
  </div>

  <div class="error" *ngIf="invalidDateTime" [ngStyle]="{'color': isDarkMode ? 'white' : 'black'}">
    {{ "TIME_ERROR" | translate }}
  </div>

  <div class="buttons">
    <button mat-button class="text-button asap" [ngClass]="isDarkMode ? 'white-text' : 'black-text'"
            (click)="asap()"> {{ "ASAP" | translate}} </button>
    <button mat-button [ngStyle]="{'background-color': this.proColor, 'color': isDarkMode ? 'black' : 'white'}"
            class="normal-button set" (click)="setTime()"> {{ "SET_TIME" | translate}} </button>
  </div>
</mat-card>

